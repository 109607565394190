// カードを新規追加するモーダルのコンポーネント

import React, { useState } from 'react';

import './PointNotEnough.css';

// React Modalで実装
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const PointNotEnough = ({modalIsOpen, changeModalIsOpen, move, shipFlag}) => {
    const { t } = useTranslation();

    const closeModal = () => {
        changeModalIsOpen(false);
    }

    Modal.setAppElement('#root');
    
    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                className="point-not-enougth-modal"
                overlayClassName="point-not-enougth-modal-overlay"
                contentLabel="Common Modal"
            >
                <div className="point-not-enougth-modal-content">
                    <div className="point-not-enougth-modal-title">{t('point_not_enough.title')}</div>
                    <div className="point-not-enougth-modal-text">
                    {t('point_not_enough.text1')}
                        {shipFlag ? 
                            <>
                            <br />
                            {t('point_not_enough.text2')}
                            </> 
                        : null}
                    </div>
                    <div className="point-not-enougth-modal-button">
                        <button className="point-not-enougth-modal-button-charge" onClick={() => {move('point')}}>{t('point_not_enough.buy')}</button>
                        <button className="point-not-enougth-modal-button-close" onClick={closeModal}>{t('point_not_enough.close')}</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default PointNotEnough;