import React from 'react'

import Main from './components/page/Main/Main';

import './App.css';

function App() {
  return (
      <>
        <Main />
      </> 
  )
}

export default App;
