import { useCallback, useRef } from 'react';

export const useDebounce = ({
  callback,
  delay = 5_000,
}) => {
  const timerRef = useRef(null);

  const debounce = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      callback();
    }, delay);
  }, [delay, callback]);

  return debounce;
};
