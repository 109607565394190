import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import Index from '../Index/Index'
import Login from '../Login/Login'
import Header from '../common/Header'
import Register from '../Login/Register'
import PasswordReset from '../Login/PasswordReset'
import Gacha from '../Gacha/Gacha'
import Buy from '../Point/Buy'
import BuyPayment from '../Point/BuyPayment'
import GachaWon from '../Gacha/GachaWon'

import Mypage from '../User/Mypage'
import Address from '../User/Address'
import AddressEdit from '../User/AddressEdit'
import Hold from '../User/Hold'
import Card from '../User/Card'
import CardEdit from '../User/CardEdit'
import Policy from '../User/Policy'
import PasswordResetRegister from '../Login/PasswordResetRegister'

import { AddressBackProvider } from '../../../provider/AddressBackProvider'
import { useTranslation } from "react-i18next";

const Main = () => {
  const { t } = useTranslation();
  const [router, setRouter] = useState(['index', undefined])
  const [isLogined, setIsLogined] = useState(false)
  const [sessionToken, setSessionToken] = useState('');
  const [mypageModalIsOpen, setMypageModalIsOpen] = useState(false)

  const [isShopLoadedText, setIsShopLoadedText] = useState('')

  let domain = window.location.hostname;

  let shopPath = window.location.pathname.split('/').pop();
  let subPath = '';
  let token = '';
  let packageId = null;
  let hitId = null;
  let isShopPayment = false;
  let wrappedCouponCode = null;

  if (shopPath === 'privacy-policy' || shopPath === 'terms-of-service' || shopPath === 'special-commercial-transaction-law') {
    subPath = shopPath;
    shopPath = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
  }

  if (shopPath === 'password-reset') {
    subPath = shopPath;
    shopPath = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    token = window.location.search.split('=')[1];
  }

  if(shopPath === 'sp') {
    shopPath = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    isShopPayment = true;

    const urlStr = window.location.toString();
    const searchParams = new URLSearchParams(urlStr.split('?').pop());

    // 検索パラメーターの列挙
    for (const p of searchParams) {
      wrappedCouponCode = p;
    }

    wrappedCouponCode = wrappedCouponCode[0];
  }

  if (Number.isInteger(Number(shopPath))) {
    packageId = shopPath;
    shopPath = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    if (shopPath === 'h') {
      hitId = packageId
      shopPath = window.location.pathname.split('/')[window.location.pathname.split('/').length - 3];
    }
  }

  const move = (r, id = undefined, option = undefined) => {
    window.history.pushState(null, null, '/' + shopPath);

    setRouter([r, id, option])
  }

  const mypage = e => {
    setMypageModalIsOpen(true)
  }

  const mypageClose = e => {
    setMypageModalIsOpen(false)
  }

  const [gotShopInfo, setGotShopInfo] = useState(false)
  const [shopInfo, setShopInfo] = useState({
    logoUrl: '',
  })
  const [gotUserInfo, setGotUserInfo] = useState(false)
  const [userInfo, setUserInfo] = useState({
    id: '',
    email: '',
    point: 0,
    fincode_customer_id: null,
  })

  const [point, setPoint] = useState(0)
  const [country, setCountry] = useState(0)

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_FINCODE_SCRIPT_URL;
    script.async = true;
    document.body.appendChild(script);

    setTimeout(() => {
      if (!gotShopInfo || !gotUserInfo) {
        setIsShopLoadedText(t('common.loading'))
      } else {
        setIsShopLoadedText('')
      }
    }, 1000)
    setTimeout(() => {
      if (!gotShopInfo || !gotUserInfo) {
        setIsShopLoadedText(t('main.error1'))
      } else {
        setIsShopLoadedText('')
      }
    }, 5000)

    if (!gotShopInfo) {
      const params = {
        shop_path: shopPath,
        domain: domain,
      }
      const query_params = new URLSearchParams(params);


      fetch(process.env.REACT_APP_API_URL + '/api/is_enabled_shop?' + query_params, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          let shop_info = data.shop_info;
          document.title = shop_info.name;
          document.getElementById("favicon").href = shop_info.favicon_url ? process.env.REACT_APP_ASSET_URL + shop_info.favicon_url : process.env.REACT_APP_ASSET_URL + '/static/default/favicon.ico';
          setShopInfo({
            id: shop_info.id,
            logoUrl: shop_info.logo_url ? process.env.REACT_APP_ASSET_URL + shop_info.logo_url : process.env.REACT_APP_ASSET_URL + '/static/default/logo.png',
            name: shop_info.name,
            infoImgs: shop_info.info_imgs,
            designPattern: shop_info.design_pattern,
            effectPattern: shop_info.effect_pattern,
            privacyPolicy: shop_info.privacy_policy,
            termsOfService: shop_info.use_terms,
            specialCommercialTransactionLaw: shop_info.special_commercial_law,
            shipFee: shop_info.ship_fee,
            noteTitle: shop_info.note_title,
            noteContent: shop_info.note_content,
            holdDays: shop_info.hold_days,
            externalDomain: shop_info.external_domain,
            isEnabledConbini: shop_info.is_enabled_conbini,
            isEnabledPayPay: shop_info.is_enabled_paypay,
            gtagId: shop_info.gtag_id,
            originalEffectPattern: shop_info.original_effect_pattern,
            fincode_tenant_id: shop_info.fincode_tenant_id,
            isShoppaymentEnabled: shop_info.is_shoppayment_enabled,
            defaultCountry: shop_info.default_country ? shop_info.default_country : 0,
            url: shop_info.url,
            email: shop_info.email,
          })

          const script = document.createElement('script');
          script.src = "https://www.googletagmanager.com/gtag/js?id=" + shop_info.gtag_id;
          script.async = true;
          document.body.appendChild(script);

          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments)
          }
          gtag('js', new Date());
          gtag('config', shop_info.gtag_id);

          // import('../../../theme/pattern-' + shop_info.design_pattern + '.css');
          import('../../../theme/pattern-6.css');

          if (shop_info.external_domain) {
            if (domain !== shop_info.external_domain) {
              let currentUrl = new URL(window.location.href);
              currentUrl.protocol = 'https';
              currentUrl.hostname = shop_info.external_domain;
              currentUrl.port = '';

              let segments = currentUrl.pathname.split('/').filter(Boolean); // 空のセグメントをフィルタリング
              segments.shift(); // 最初のセグメントを除去
              currentUrl.pathname = '/' + segments.join('/');


              window.location.href = currentUrl.href;
            }
          }

          let token = document.cookie.split('; ').find(row => row.startsWith('token'))
          if (token) {
            token = token.split('=')[1];
            fetch(process.env.REACT_APP_API_URL + '/api/is_logined?' + 'shop_id=' + shop_info.id, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
              credentials: 'include',
            })
              .then(response => response.json())
              .then(data => {
                if (data.is_logined) {
                  setIsLogined(true);
                  setSessionToken(token);

                  fetch(process.env.REACT_APP_API_URL + '/api/user', {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${decodeURIComponent(token)}`,
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                    },
                    credentials: 'include',
                  })
                    .then(response => response.json())
                    .then(data => {
                      if (data.user) {
                        setUserInfo({
                          id: data.user.id,
                          email: data.user.email,
                          point: data.user.balance,
                          expired_point: data.user.expired_balance.balance,
                          expired_date: data.user.expired_balance.expired_date,
                          fincode_customer_id: data.user.fincode_customer_id,
                        })
                      }
                      setGotUserInfo(true);
                      setIsShopLoadedText('');

                      if (isShopPayment) {
                        window.history.pushState(null, null, '/' + shopPath);
                        setRouter(['point', wrappedCouponCode]);
                      }
                      
                    }).catch(error => {
                      setTimeout(() => {
                        setIsShopLoadedText(t('main.error2'));
                      }, 10000)
                    });
                } else {
                  setGotUserInfo(true);
                  setIsShopLoadedText('');
                }
              }).catch(error => {
                setTimeout(() => {
                  setIsShopLoadedText(t('main.error2'));
                }, 10000)
              });
          } else {
            setGotUserInfo(true);
            setIsShopLoadedText('');
          }
        }).catch(error => {
          setTimeout(() => {
            setIsShopLoadedText(t('main.error2'));
          }, 10000)
        });
    }
  }, [])

  return (
    <>
      {shopInfo.id &&
        <>
          {gotUserInfo &&
            <>
              {isLogined &&
                <>
                  {subPath ?
                    <>
                      {subPath === 'privacy-policy' && <Policy shopInfo={shopInfo} subPath={subPath} />}
                      {subPath === 'terms-of-service' && <Policy shopInfo={shopInfo} subPath={subPath} />}
                      {subPath === 'special-commercial-transaction-law' && <Policy shopInfo={shopInfo} subPath={subPath} />}
                      <footer>
                          <div className="footer-1">
                          <div className="footer-company">
                              <a href={`${shopPath}/`}
                              target="_blank">{t('common.company')}</a>
                          </div>
                          <div className="footer-policy">
                              <a href={`${shopPath}/terms-of-service`}
                              target="_blank">{t('common.service')}</a>
                          </div>
                          <div className="footer-law">
                              <a href={`${shopPath}/special-commercial-transaction-law`}
                                  target="_blank">{t('common.law')}</a>
                          </div>
                          </div>
                          <div className="footer-2">
                          <div className="footer-privacy">
                              <a href={`${shopPath}/privacy-policy`}
                              target="_blank">{t('common.privacy')}</a>
                          </div>
                          <div className="footer-qa">
                              <a href={`mailto:${shopInfo.email}`}
                              target="_blank">{t('common.contact')}</a>
                          </div>
                          </div>
                          <div className="footer-shop">
                              <div className="footer-logo">
                                  <img src={shopInfo.logoUrl} alt="logo" onClick={e => move('index')} />
                              </div>
                              <div className="footer-name">
                                  Powered by {shopInfo.name}
                              </div>
                          </div>
                      </footer>
                    </>
                    :
                    <>
                      <AddressBackProvider>
                        <Header
                          isLogined={isLogined}
                          move={move}
                          router={router}
                          mypage={mypage}
                          shopInfo={shopInfo}
                          userInfo={userInfo}
                        />
                        {/* <div id='content'> */}
                        <div id='content' className={router[0] === 'onhold' ? 'hold' : ''}>
                          <div class="headerShadow"></div>
                          {hitId ?
                            <>
                              <GachaWon move={move} id={hitId} userInfo={userInfo} setUserInfo={setUserInfo} shopPath={shopPath} shopInfo={shopInfo} setShopInfo={setShopInfo} />
                            </>
                            : packageId ?
                              <>
                                <Gacha move={move} shopInfo={shopInfo} userInfo={userInfo} setUserInfo={setUserInfo} shopPath={shopPath} packageId={packageId} gachaType={router[2]}/>
                              </>
                              :
                              <>
                                {router[0] === 'index' && <Index move={move} shopInfo={shopInfo} userInfo={userInfo}/>}
                                {router[0] === 'gacha' && <Gacha move={move} id={router[1]} shopInfo={shopInfo} userInfo={userInfo} setUserInfo={setUserInfo} shopPath={shopPath} gachaType={router[2]}/>}
                                {router[0] === 'gacha-won' && <GachaWon move={move} id={router[1]} userInfo={userInfo} setUserInfo={setUserInfo} shopPath={shopPath} shopInfo={shopInfo} setShopInfo={setShopInfo} />}
                                {router[0] === 'point' && 
                                  <Buy move={move} wrappedCouponCode={router[1]} 
                                    shopInfo={shopInfo} userInfo={userInfo} setUserInfo={setUserInfo} setPoint={setPoint} country={country} setCountry={setCountry}
                                  />
                                }
                                {router[0] === 'payment' && <BuyPayment userInfo={userInfo} shopInfo={shopInfo} point={point} country={country} />}
                                {router[0] === 'onhold' && <Hold move={move} router={router} userInfo={userInfo} setUserInfo={setUserInfo} shopPath={shopPath} shopInfo={shopInfo} setShopInfo={setShopInfo} />}
                                {router[0] === 'card' && <Card move={move} userInfo={userInfo} shopInfo={shopInfo} />}
                                {router[0] === 'card-edit' && <CardEdit move={move} shopInfo={shopInfo} />}
                                {router[0] === 'address' && <Address move={move} mypage={mypage} />}
                                {router[0] === 'address-edit' && <AddressEdit move={move} id={router[1]} mypage={mypage} />}
                              </>
                          }
                          <Mypage
                            modalIsOpen={mypageModalIsOpen}
                            closeModal={mypageClose}
                            move={move}
                            router={router}
                            shopInfo={shopInfo}
                            userInfo={userInfo}
                            shopPath={shopPath}
                          />
                        </div>
                        {/* {router[0] != 'onhold' ?
                            <div className="">
                                aa
                            </div>
                            :
                            <div className="">
                              bb
                            </div>
                        } */}
                        {router[0] !== 'gacha-won' && router[0] !== 'onhold' &&
                        <footer>
                            <div className="footer-1">
                            <div className="footer-company">
                              <a href={shopInfo.url} 
                              target="_blank">{t('common.company')}</a>
                            </div>
                            <div className="footer-policy">
                                <a href={`${shopPath}/terms-of-service`}
                                target="_blank">{t('common.service')}</a>
                            </div>
                            <div className="footer-law">
                                <a href={`${shopPath}/special-commercial-transaction-law`}
                                    target="_blank">{t('common.law')}</a>
                            </div>
                            </div>
                            <div className="footer-2">
                            <div className="footer-privacy">
                                <a href={`${shopPath}/privacy-policy`}
                                target="_blank">{t('common.privacy')}</a>
                            </div>
                            <div className="footer-qa">
                                <a href={`mailto:${shopInfo.email}`}
                                target="_blank">{t('common.contact')}</a>
                            </div>
                            </div>
                            <div className="footer-shop">
                                <div className="footer-logo">
                                    <img src={shopInfo.logoUrl} alt="logo" onClick={e => move('index')} />
                                </div>
                                <div className="footer-name">
                                    Powered by {shopInfo.name}
                                </div>
                            </div>
                        </footer>
                        }
                      </AddressBackProvider>
                    </>
                  }
                </>
              }
              {!isLogined &&
                <>
                  {subPath === 'privacy-policy' && <Policy shopInfo={shopInfo} subPath={subPath} />}
                  {subPath === 'terms-of-service' && <Policy shopInfo={shopInfo} subPath={subPath} />}
                  {subPath === 'special-commercial-transaction-law' && <Policy shopInfo={shopInfo} subPath={subPath} />}
                  {subPath === 'password-reset' && <PasswordResetRegister shopInfo={shopInfo} token={token} move={move} />}
                  {subPath ?
                    <></>
                    :
                    <>
                      <Header
                        isLogined={isLogined}
                        move={move}
                        router={router}
                        shopInfo={shopInfo}
                        userInfo={userInfo}
                      />
                      <div id='content'>
                        <div class="headerShadow"></div>
                        {hitId ?
                          <>
                            <Index move={move} shopInfo={shopInfo} userInfo={userInfo}/>
                          </>
                          : packageId ?
                            <>
                              <Gacha move={move} id={router[1]} shopInfo={shopInfo} shopPath={shopPath} packageId={packageId} gachaType={router[2]}/>
                            </>
                            :
                            <>
                              {router[0] === 'index' && <Index move={move} shopInfo={shopInfo} userInfo={userInfo}/>}
                              {router[0] === 'gacha' && <Gacha move={move} id={router[1]} shopInfo={shopInfo} shopPath={shopPath} gachaType={router[2]}/>}
                              {router[0] === 'login' && <Login move={move} shopInfo={shopInfo} setIsLogined={setIsLogined} setSessionToken={setSessionToken} shopPath={shopPath} />}
                              {router[0] === 'register' && <Register move={move} shopInfo={shopInfo} setIsLogined={setIsLogined} setSessionToken={setSessionToken} shopPath={shopPath} />}
                              {router[0] === 'passwordReset' && <PasswordReset move={move} shopInfo={shopInfo} />}
                            </>
                        }
                      </div>
                      {/* {router[0] != 'login' &&
                          <div className="login-footer footer-sub">
                              <a href={`${shopPath}/special-commercial-transaction-law`}
                                  target="_blank">{t('footer.law')}</a>　
                                  <a href={`mailto:${shopInfo.email}`}>{t('footer.contact')}</a>
                          </div>
                      } */}
                      <footer>
                          <div className="footer-1">
                          <div className="footer-company">
                              <a href={shopInfo.url} 
                              target="_blank">{t('common.company')}</a>
                          </div>
                          <div className="footer-policy">
                              <a href={`${shopPath}/terms-of-service`}
                              target="_blank">{t('common.service')}</a>
                          </div>
                          <div className="footer-law">
                              <a href={`${shopPath}/special-commercial-transaction-law`}
                                  target="_blank">{t('common.law')}</a>
                          </div>
                          </div>
                          <div className="footer-2">
                          <div className="footer-privacy">
                              <a href={`${shopPath}/privacy-policy`}
                              target="_blank">{t('common.privacy')}</a>
                          </div>
                          <div className="footer-qa">
                              <a href={`mailto:${shopInfo.email}`}
                              target="_blank">{t('common.contact')}</a>
                          </div>
                          </div>
                          <div className="footer-shop">
                              <div className="footer-logo">
                                  <img src={shopInfo.logoUrl} alt="logo" onClick={e => move('index')} />
                              </div>
                              <div className="footer-name">
                                  Powered by {shopInfo.name}
                              </div>
                          </div>
                      </footer>
                    </>
                  }
                </>
              }
            </>
          }
        </>
      }
      {!gotUserInfo &&
        <>
          {isShopLoadedText}
        </>
      }
    </>
  )
}

export default Main
