import React from 'react'
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Index.css'
import { useTranslation } from "react-i18next";

const Banner = ({shopInfo}) => {
    const { t } = useTranslation();

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnHover: true,
        swipeToSlide: true,
        arrows: false,
        dotsClass: "slide-dots"
    };

    const BannersList = () => {
        if (shopInfo.infoImgs) {
            return  (
                <>
                <Slick {...settings}>
                {shopInfo.infoImgs.map((info, index) => {
                    let domain = window.location.href.split('/')[2]
                    if (info[1].indexOf(domain) !== -1) {
                        return (
                            <a href={info[1]} key={index}>
                                <img src={process.env.REACT_APP_ASSET_URL+info[0]} alt={t('banner.info')} />
                            </a>
                        )
                    } else {
                        return (
                            <a href={info[1]} target='_blank' key={index}>
                                <img src={process.env.REACT_APP_ASSET_URL+info[0]} alt={t('banner.info')} />
                            </a>
                        )
                    }
                })}
                </Slick>
                </>
            )
        }
    }


    return (
        <>
            <div className="banners">
                <div className="banner slider">
                    <BannersList />
                </div>
            </div>
        </>
    )
}

export default Banner