import { useState, useEffect } from 'react'
import { api } from '../../../lib/api'
import { useFincode } from '../../../lib/fincode'

import Settlement from './Modal/Settlement'

import './Point.css'
import { useTranslation } from "react-i18next";

const BuyPayment = ({ userInfo, shopInfo, point, country }) => {
  const { t } = useTranslation();
  const [ui, setUi] = useState(null)
  const [cards, setCards] = useState([])
  const [cardSelect, setCardSelect] = useState('')
  const [cardId, setCardId] = useState('')
  const [confirmOpened, setConfirmOpened] = useState(false)

  const [jpCurrency, setJpCurrency] = useState(0)
  const [currency, setCurrency] = useState(0)

    // /shop-rates
const [currencies, setCurrencies] = useState([]);
const [points, setPoints] = useState([]);
const [jpCurrencies, setJpCurrencies] = useState([]);

useEffect(() => {
    let token = document.cookie.split('; ').find(row => row.startsWith('token'));
    if (token) {
        token = token.split('=')[1]
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/shop-rate?country=0`, {
        headers: {
          'Authorization': `Bearer ${decodeURIComponent(token)}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        let newCurrencies = [...currencies];
        let newPoints = [...points];
        data.shop_rates.forEach((rate, index) => {
            newCurrencies[index] = rate.rate;
            newPoints[index] = rate.point;
        });
        setJpCurrencies(newCurrencies);
        let pointIndex = newPoints.findIndex(p => p > point);
        setJpCurrency(newCurrencies[pointIndex - 1]);
        setSettlement({...settlement, point: newCurrencies[pointIndex - 1]})
    })
  }, []);

useEffect(() => {
    let token = document.cookie.split('; ').find(row => row.startsWith('token'));
    if (token) {
        token = token.split('=')[1]
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/shop-rate?country=${country}`, {
        headers: {
            'Authorization': `Bearer ${decodeURIComponent(token)}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        let newCurrencies = [...currencies];
        let newPoints = [...points];
        data.shop_rates.forEach((rate, index) => {
            newCurrencies[index] = rate.rate;
            newPoints[index] = rate.point;
        });
        setCurrencies(newCurrencies);
        setPoints(newPoints);
        let pointIndex = newPoints.findIndex(p => p > point);
        setCurrency(newCurrencies[pointIndex - 1]);
    })

  }, [country]);

  const [settlement, setSettlement] = useState({
    point: jpCurrency,
    customer_id: null,
    card_id: null,
    card_no: null,
    expire: null,
    security_code: null,
  })
  const [addCard, setAddCard] = useState(false)

  const fincode = useFincode(shopInfo.fincode_tenant_id)
  // const fincode = useFincode()

  const toggleConfirm = () => setConfirmOpened(!confirmOpened)

  const getCard = async () => {
    try {
      const {
        cardNo: card_no,
        expire,
        holderName: holder_name,
        CVC: security_code,
      } = await ui.getFormData()

      // 登録済みカード
      if (cardSelect === 'normal' && cardId)
        return { ...settlement, customer_id: userInfo.fincode_customer_id, card_id: cardId }

      const date = new Date()
      if (`${String(date.getFullYear()).slice(2)}${String(date.getMonth() + 1).padStart(2, '0')}` > expire)
        throw new Error(t('buy_payment.expire_error'))

      // 新規カードで登録なし
      if (!addCard) return { ...settlement, card_no, expire, security_code }

      // 新規カードで登録あり
      const customer = await api.post('/user/customers')
      const response = await fincode.cards({
        customer_id: customer.id,
        default_flag: '1',
        card_no,
        expire,
        holder_name,
        security_code
      })
      return { ...settlement, customer_id: customer.id, card_id: response.id }

    } catch (e) {
      // console.log(e)
      if (e.errors)
        return { error: e.errors.map(e => e.error_message).join("\n") }

      return { error: e.message }
    }
  }

  const handleCardSelect = (e) => setCardSelect(e.target.value)
  const handleCardId = (e) => setCardId(e.target.value)
  const handleCardPayment = async () => {
    try {
      const card = await getCard()

      // console.log(card)
      if (card.error) throw new Error(card.error)
      setSettlement(card)
      toggleConfirm()
    } catch (e) {
      // console.log(e)
      alert(e?.message || t('common.error'))
      return
    }
  }

  const handlePaymentResult = async (status, orderId, error = null, cardId = null) => {
    if (status === "success") {
      await api.post(`/point/success/${orderId}`)
      alert(t('buy_payment.buy'))
      window.location.reload()
    }
    if (status === "failure") {
      if (addCard && cardId) await api.delete(`/user/customers/cards/${cardId}`)
      await api.post(`/point/cancel/${orderId}`)
      alert(error ?? t('buy_payment.error'))
      toggleConfirm()
    }
  }

  const handleEPayment = async () => {
    try {
      const res = await api.post('point/buy', { point: jpCurrency })
      if (res.errors) {
        alert(Object.values(res.errors)[0])
        return
      }
      window.location.href = res.link_url
    } catch (e) {
      alert(t('common.error'));
      return
    }
  }

  const ePaymentButton = () => {
    const enabledConbini = !!shopInfo.isEnabledConbini && jpCurrency <= 300000
    const enabledPayPay = !!shopInfo.isEnabledPayPay
    if (!enabledConbini && !enabledPayPay)
      return null

    const text = (() => {
      if (enabledConbini && enabledPayPay)
        return "コンビニ決済またはPayPayで支払う"
      if (enabledConbini)
        return "コンビニ決済で支払う"
      if (enabledPayPay)
        return "PayPayで支払う"
    })()

    return (
      <button type="button" onClick={handleEPayment} class="fincode-button">
        {text}
      </button>
    )
  }

  const getCards = async (customerId) => {
    let fincodeCards = await fincode.listCard(customerId)
    
    fincodeCards.sort((a, b) => {
      if (a.created < b.created) return 1
      if (a.created > b.created) return -1
      return 0
    })

    fincodeCards.sort((a, b) => {
      if (a.default_flag === '1') return -1
      if (b.default_flag === '1') return 1
      return 0
    })

    setCards(fincodeCards)
    setCardId(fincodeCards[0].id)
  }

  useEffect(() => {
    if (userInfo.fincode_customer_id)
      getCards(userInfo.fincode_customer_id)

    setCardSelect(userInfo.fincode_customer_id ? 'normal' : 'new')

    const root = document.documentElement
    const css = getComputedStyle(root)

    const backgroundColor = css.getPropertyValue('--mypage-color').replace('#', '')
    const colorText = css.getPropertyValue('--body-font-color').replace('#', '')
    const colorBackgroundInput = css.getPropertyValue('--login-form-color').replace('#', '')
    const colorCheck = css.getPropertyValue('--login-button-color').replace('#', '')
    const colorPlaceHolder = css.getPropertyValue('--login-form-placeholder-color').replace('#', '')

    const appearance = {
      hideLabel: false, hideHolderName: false, hidePayTimes: true,
      colorBackground: backgroundColor,
      colorBackgroundInput: colorBackgroundInput,
      colorText: colorText,
      colorPlaceHolder: colorPlaceHolder,
      colorLabelText: colorText,
      colorBorder: colorBackgroundInput,
      colorError: "c12424",
      colorCheck: colorCheck,
      colorRadio: colorCheck,
      colorRadioText: colorText,
      colorSelect: colorCheck,
      colorBackgroundRadio: colorBackgroundInput,
      labelCardNo: t('buy_payment.card'), labelExpire: t('buy_payment.expire'), labelCvc: t('buy_payment.cvc'), labelHolderName: t('buy_payment.holder'),
    }
    const width = parseInt(document.getElementById('content').offsetWidth * 0.90 - 30)

    setUi(fincode.ui("fincode", "payments", width, appearance))

    const fincodeAmount = document.querySelector('.fincode-amount')
    fincodeAmount.style.width = `${width + 30}px`

    // fincode-form
    // 1秒待つ
    setTimeout(() => {
      const fincodeForm = document.querySelector('#fincode-form')
      fincodeForm.style.height = "auto"
    }, 1000)
  }, [])

  return (
    <>
      <div id="fincode-wrap">
        <div class="fincode-amount">
        <div>{t('buy_payment.title')}</div>
          <div className='fincode-amount-value'>
            {point && jpCurrency && currency &&
              <>
                ￥{parseInt(jpCurrency).toLocaleString()} ({parseInt(point).toLocaleString()}pt)
                {country != 0 &&
                  <>
                  ≒ {country == 0 ? '¥' : country == 1 ? 'NT$' : country == 2 ? 'HK$' : 'S$'}{parseInt(currency).toLocaleString()}
                  </>
                }
              </>
            }
          </div>
        </div>
        <form id="fincode-form">
          <div id="fincode-list">
            <input id="normal" class="basic-radio-input card-select-radio" type="radio" name="cardSelect" data-v-6d3ad3d2="" value='normal' checked={cardSelect === 'normal'} onChange={handleCardSelect} />
            <label class="basic-radio-label card-select-label" for="normal" data-v-6d3ad3d2="">
            {t('buy_payment.registered_card')}
            </label>
            <select id="" class="basic-select card-select-select" name="card-list" data-v-4de72cc5="" onChange={handleCardId}>
              {cards.map(c => (<option key={c.id} value={c.id}>{c.brand} {c.card_no.replace(/(.{4})/g, '$1 ')}({c.expire.slice(0,2)}/{c.expire.slice(2,4)})</option>))}
            </select>
          </div>
          <div id="fincode-new">
            <input id="new" class="basic-radio-input card-select-radio" type="radio" name="cardSelect" data-v-6d3ad3d2="" value='new' checked={cardSelect === 'new'} onChange={handleCardSelect} />
            <label class="basic-radio-label card-select-label" for="new" data-v-6d3ad3d2="">{t('buy_payment.new_card')}</label>
          </div>
          <div id="fincode">
          </div>
          <div className='new-card'>
            <input type="checkbox" id="add-card" checked={addCard} onChange={() => setAddCard(!addCard)} class="fincode-check" />
            <label for="add-card">{t('buy_payment.register_check')}</label>
          </div>
          <div className='payment-button'>
            <button type="button" onClick={handleCardPayment} class="fincode-button">
            <span>{t('buy_payment.pay')}</span>
            </button>
          </div>
          <div className='e-payment-button'>
            {ePaymentButton()}
          </div>
        </form>
      </div>
      <Settlement confirmOpened={confirmOpened} settlement={settlement} fincode={fincode} handlePaymentResult={handlePaymentResult} />
    </>
  )
}

export default BuyPayment
