import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import {AddressBackContext} from '../../../provider/AddressBackProvider'

import './User.css'
import { useTranslation } from "react-i18next";
const Address = ({move, mypage}) => {
    const { t } = useTranslation();
    const {addressBack, setAddressBack} = useContext(AddressBackContext);

    const moveBackPage = () => {
        if (submitButton == t('address.saving')) {
            return
        }

        setSubmitButton(t('address.saving'))
        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }
        // ラジオボタンの値を取得
        const radio = document.querySelector('input[type="radio"]:checked')
        const id = radio.value
        fetch(process.env.REACT_APP_API_URL + `/api/address/default/${id}`, {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Accept': 'application/json',
            },
            credentials: 'include',
        })
        .then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                throw new Error('Failed to get address')
            }
        })
        .then(data => {
            if (addressBack[0] == 'mypage') {
                mypage()
                move(addressBack[1], addressBack[2])
            }
            if (addressBack[0] == 'gacha-won') {
                move(addressBack[0], addressBack[1])
            }
            if (addressBack[0] == 'onhold') {
                move(addressBack[0])
            }
            setSubmitButton(t('address.save'))
        })
        .catch(error => {
            console.log(error)
            setSubmitButton(t('address.save'))
        });
    }

    const deleteAddress = (e) => {
        
        if (!window.confirm(t('address.confirm'))) {
            return
        }
        
        e.target.innerText = t('address.deleting')

        const id = e.currentTarget.dataset.id

        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }
        fetch(process.env.REACT_APP_API_URL + `/api/address/delete/${id}`, {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Accept': 'application/json',
            },
            credentials: 'include',
        })
        .then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                throw new Error('Failed to get address')
            }
        })
        .then(data => {
            if(data.user_addresses)
                setUserAddressList(data.user_addresses)
            else
                setUserAddressList([])
            alert(t('address.success'))
            e.target.innerText = t('address.delete')
        })
        .catch(error => {
            alert(t('address.failure'))
            e.target.innerText = t('address.delete')
            console.log(error)
        });
    }

    const moveRegisterAddress = (e) => {
        let id = e.currentTarget.dataset.id
        move('address-edit', id)
    }

    const selectAddress = (e) => {
        const address = e.currentTarget
        const radio = address.querySelector('input[type="radio"]')
        radio.checked = true
    }

    const selectAddressRadioButton = (e) => {
        setUserAddressList(userAddressList.map((userAddress, index) => {
            if (userAddress.id == e.currentTarget.value) {
                userAddress.is_default = true
            } else {
                userAddress.is_default = false
            }
            return userAddress
        }))
        e.currentTarget.checked = true
    }

    const [userAddressList, setUserAddressList] = useState([])

    useEffect(() => {
        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }
        fetch(process.env.REACT_APP_API_URL + '/api/address', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Accept': 'application/json',
            },
            credentials: 'include',
        })
        .then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                throw new Error('Failed to get address')
            }
        })
        .then(data => {
            setUserAddressList(data.user_addresses)
            setLoading(false)
        })
        .catch(error => {
            alert(t('address.error'))
        });
    }, [])

    const CreateAddressList = () => {
        if (!userAddressList){
            return (
                <>
                </>
            )
        } else {
            return userAddressList.map((userAddress, index) => {
                return (
                    <div className="user-address-item" onClick={selectAddress} data-id={userAddress.id} key={index}>
                        <div className="user-address-item-radio">
                            <input type="radio" name="address" {...userAddress.is_default ? {checked: true} : {checked: false}} onChange={selectAddressRadioButton} value={userAddress.id} />
                        </div>
                        <div className="user-address-item-name">{userAddress.last_name} {userAddress.first_name}</div>
                        {userAddress.country == "0" &&
                            <div className="user-address-item-postcode">〒{userAddress.address.postal_code}</div>
                        }
                        <div className="user-address-item-address">{userAddress.address.address}</div>
                        <div className="user-address-item-button">
                            <button className="user-address-item-edit-button address-edit-button" onClick={moveRegisterAddress} data-id={userAddress.id}>{t('address.edit')}</button>
                            <button className="user-address-item-delete-button address-delete-button" onClick={deleteAddress} data-id={userAddress.id}>{t('address.delete')}</button>
                        </div>
                    </div>
                )
            })
        }
    }
    
    const [loading, setLoading] = useState(true)
    const [submitButton, setSubmitButton] = useState(t('address.decision'));

    return (
        <>
            <div className="user-address">
                <div className="user-address-title">{t('address.title')}</div>
                {loading &&
                     <>
                        <div className="user-address-list">
                            {t('common.loading')}
                        </div>
                    </>
                }
                {userAddressList.length != 0 ?
                    <>
                        <div className="user-address-list">
                            <CreateAddressList />
                        </div>
                        <div className="line"></div>
                        <div className="user-address-button">
                            <button className="user-address-button-add" onClick={moveRegisterAddress}>＋ {t('address.add')}</button>
                            <button className="user-address-button-close" onClick={moveBackPage}>{submitButton}</button>
                        </div>
                    </>
                    :
                    <>
                        <div className="user-address-button">
                            <button className="user-address-button-add" onClick={moveRegisterAddress}>＋ {t('address.add')}</button>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Address