import { useState, useEffect, useRef } from 'react'

import BuyPointConfirm from './Modal/BuyPointConfirm'
import ShopCoupon from './ShopCoupon'

import './Point.css'
import { useTranslation } from "react-i18next";

const Buy = ({ move, wrappedCouponCode, shopInfo, userInfo, setUserInfo, setPoint, country, setCountry }) => {

  const { t } = useTranslation();
  const [buttonPushed, setButtonPushed] = useState(false)
  const [settleHistory, setSettleHistory] = useState([])

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [pushedButton, setPushedButton] = useState(null);

  const [shopPaymentTabSelected, setShopPaymentTabSelected] = useState(false)
  const [shopPaymentResultMessage, setShopPaymentResultMessage] = useState('');
  const [shopPaymentResult, setShopPaymentResult] = useState('');
  
  const isFirstRender = useRef(true);

  const togglePaymentType = () => {
    setShopPaymentTabSelected(!shopPaymentTabSelected);
  }

  const changeModalIsOpen = (value) => {
    setModalIsOpen(value);
  }

  const buyPointConfirm = (e) => {
    let value = e.target.value;

    if (value >= 500000) {
      setModalIsOpen(true);
      setPushedButton(e);
    }
  }

  const buyPoint = (e) => {
    if (!e) {
      e = pushedButton;
    }

    let value = e.target.value;

    let token = document.cookie.split('; ').find(row => row.startsWith('token'));
    if (token) {
      token = token.split('=')[1]
    }

    if (buttonPushed) return;

    e.target.innerHTML = t('common.loading');
    setButtonPushed(true);

    setPoint(value)
    setCountry(country)
    move('payment')
  }

  useEffect(() => {
    let token = document.cookie.split('; ').find(row => row.startsWith('token'));
    if (token) {
      token = token.split('=')[1]
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/point/history`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${decodeURIComponent(token)}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        if (!data.errors) {
          setSettleHistory(data.settle_history);
        } else {
          alert(Object.values(data.errors)[0]);
        }
      })
      .catch(error => {
        alert(t('common.error'));
      });
  }, [])

  const handleToDate = (date) => {
    date = new Date(date);
    if (date.getMinutes() < 10) {
      date = date.getFullYear() + "/" + (date.getMonth() % 12 + 1) + "/" + date.getDate() + " " + date.getHours() + ":0" + date.getMinutes()
    } else {
      date = date.getFullYear() + "/" + (date.getMonth() % 12 + 1) + "/" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes()
    }

    return date;
  }


  useEffect(() => {
        
    // if (isFirstRender.current) {
    //     isFirstRender.current = false;
    //     return;
    // }

    if (wrappedCouponCode) {
        
        setShopPaymentTabSelected(true);

        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }


        fetch(`${process.env.REACT_APP_API_URL}/api/shoppayment/${shopInfo.id}/add-point-auto`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                shop_id: shopInfo.id,
                wraped_coupon_code: wrappedCouponCode,
            })
        })
        .then(response => response.json())
        .then(data => {
            if(!data.errors) {

                setShopPaymentResultMessage(data.message);

                if (data.hasError) {
                    setShopPaymentResult('error');

                } else {
                    window.alert(t('buy.completed'));
                    window.history.pushState(null,null,"/");
                    window.location.href = data.url;

                }

            } else {
                alert(Object.values(data.errors)[0]);
            }
        })
        .catch(error => {
            setShopPaymentResultMessage(error.message);
            setShopPaymentResult('error');
            alert(t('common.error'));
        });

    
    }


}, [shopInfo.id, wrappedCouponCode]);

// /shop-rates
const [currencies, setCurrencies] = useState([]);
const [points, setPoints] = useState([]);
const [jpCurrencies, setJpCurrencies] = useState([]);

useEffect(() => {
    let token = document.cookie.split('; ').find(row => row.startsWith('token'));
    if (token) {
        token = token.split('=')[1]
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/shop-rate?country=0`, {
        headers: {
          'Authorization': `Bearer ${decodeURIComponent(token)}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        let newCurrencies = [...currencies];
        let newPoints = [...points];
        data.shop_rates.forEach((rate, index) => {
            newCurrencies[index] = rate.rate;
            newPoints[index] = rate.point;
        });
        setJpCurrencies(newCurrencies);
    })
  }, []);


useEffect(() => {
    let token = document.cookie.split('; ').find(row => row.startsWith('token'));
    if (token) {
        token = token.split('=')[1]
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/shop-rate?country=${country}`, {
        headers: {
            'Authorization': `Bearer ${decodeURIComponent(token)}`,
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        let newCurrencies = [...currencies];
        let newPoints = [...points];
        data.shop_rates.forEach((rate, index) => {
            newCurrencies[index] = rate.rate;
            newPoints[index] = rate.point;
        });
        setCurrencies(newCurrencies);
        setPoints(newPoints);
    })

  }, [country]);

  const CreateBuyPointList = () => {
    let list = [];
    jpCurrencies.forEach((currency, index) => {
      list.push(
        <div className="point-list-item" key={index}>
          <img src="img/balance.svg" alt="balance" /> {Number(points[index]).toLocaleString()}pt
          <button className="point-list-item-button" onClick={buyPoint} value={points[index]}>
            ¥ {Number(currency).toLocaleString()}
            {country != 0 && 
              <>
                <br />
                <span className="point-list-item-button-currency">
                  ≒ 
                  {country == 0 ? '¥' : country == 1 ? 'NT$' : country == 2 ? 'HK$' : 'S$'}
                  {Number(currencies[index]).toLocaleString()}
                </span>
              </>
            }
          </button>
        </div>
      );
    });

    return list;
  }

  return (
    <>
      <div className="point">
      { !wrappedCouponCode && 
        <>
          <div className="point-back">
            <button onClick={() => move('index')}>
            {t('buy.back')}
            </button>
          </div>
          
          <div className="point-title">{t('buy.title')}</div>
          <div className="country-select">
            {/* <select name="country" onChange={(e) => setCountry(e.target.value)}>
              <option value="0"><img src="/img/Japan-Flag.svg" alt="ja" />{t('buy.japan')}</option>
              <option value="1">{t('buy.taiwan')}</option>
              <option value="2">{t('buy.hongkong')}</option>
              <option value="3">{t('buy.singapore')}</option>
            </select> */}
            <div class="btn-group">
                <button type="button" class="btn btn-default dropdown-toggle language-change-button" data-toggle="dropdown" aria-expanded="false">
                    <img src={country == 0 ? "/img/Japan-Flag.svg": country == 1 ? '/img/Taiwan-Flag.svg' : country == 2 ? '/img/Hong-Kong-Flag.svg' : '/img/Singapore-Flag.svg'}/>
                    {country == 0 ? '日本' : country == 1 ? '台湾' : country == 2 ? '香港' : 'シンガポール'}
                </button>
                <ul class="dropdown-menu" role="menu" >
                    <li onClick={(e) => setCountry("0")}>
                        <a class="dropdown-item" href="#">
                          <img src="/img/Japan-Flag.svg" alt="ja" />{t('buy.japan')}
                        </a>
                    </li>
                    <li onClick={(e) => setCountry("1")}>
                        <a class="dropdown-item" href="#">
                          <img src="/img/Taiwan-Flag.svg" alt="ta" />{t('buy.taiwan')}
                        </a>
                    </li>
                    <li onClick={(e) => setCountry("2")}>
                        <a class="dropdown-item" href="#">
                          <img src="/img/Hong-Kong-Flag.svg" alt="ho" />{t('buy.hongkong')}
                        </a>
                    </li>
                    <li onClick={(e) => setCountry("3")}>
                        <a class="dropdown-item" href="#">
                          <img src="/img/Singapore-Flag.svg" alt="si" />{t('buy.singapore')}
                        </a>
                    </li>
                </ul>
            </div>
          </div>
          <div className="point-description">
          <img src="/img/information.svg" alt="ja" />
          <div>
            {t('buy.description1')}<br />
            {t('buy.description2')}<br />
            {t('buy.description3')}
          </div>
          </div>
        </>
        }

        { !wrappedCouponCode && (shopInfo.isShoppaymentEnabled === 1) &&
            <div className="contents">
                <div className="package-nav">
                    <ul className="package-types" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <li style={{ width: '50%', textAlign: 'center' }} className={`package-type ${ (!shopPaymentTabSelected) ? 'package-type-selected' : ''}`} onClick={togglePaymentType}>{t('buy.bynet')}</li>
                        <li style={{ width: '50%', textAlign: 'center' }} className={`package-type ${ (shopPaymentTabSelected) ? 'package-type-selected' : ''}`} onClick={togglePaymentType}>{t('buy.bystore')}</li>
                    </ul>
                </div>
            </div>
        }

        { !wrappedCouponCode && !shopPaymentTabSelected && 
          <>
            <div className="point-list">
              <CreateBuyPointList />
            </div>
          </>
        }

        { shopPaymentResult === 'error' &&
          <div className="shop-payment-error">
            { shopPaymentResultMessage }
          </div>
        }
        { !wrappedCouponCode && shopPaymentTabSelected && 
          <>
            <div>
              <ShopCoupon shopInfo={shopInfo} userInfo={userInfo} 
                setShopPaymentResult={setShopPaymentResult}
                setShopPaymentMessage={setShopPaymentResultMessage} />
            </div>
          </>
        }

        { !wrappedCouponCode && 
          <>
          <div className="point-settle-history">
            <div className="point-settle-history-title">{t('buy.history')}</div>
            <table className="point-settle-history-list">
              <thead>
                <tr>
                <th>{t('buy.date')}</th>
                  <th>{t('buy.point')}</th>
                </tr>
              </thead>
              <tbody>
                {settleHistory.map((history, index) => (
                  <tr className="point-settle-history-list-item" key={index}>
                    <td className="point-settle-history-list-item-date">{handleToDate(history.created_at)}</td>
                    <td className="point-settle-history-list-item-value">{Number(history.point).toLocaleString()} pt</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </>
        }
        
        { wrappedCouponCode && shopPaymentResult !== 'error' &&
            <>{t('buy.processing')}</>
        }

      </div>
      <BuyPointConfirm modalIsOpen={modalIsOpen} changeModalIsOpen={changeModalIsOpen} buyPoint={buyPoint} />
    </>
  )
}

export default Buy
