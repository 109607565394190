import { useState, useEffect } from 'react'
import { api } from '../../../lib/api'
import { useFincode } from '../../../lib/fincode'
import { useTranslation } from "react-i18next";

import './User.css'

// svg from: https://github.com/twltwl/react-pay-icons/tree/master/IconsSource
import { ReactComponent as AMEX } from "../../assets/images/cards/amex.svg";
import { ReactComponent as DINERS } from "../../assets/images/cards/diners.svg";
import { ReactComponent as JCB } from "../../assets/images/cards/jcb.svg";
import { ReactComponent as MASTER } from "../../assets/images/cards/mastercard.svg";
import { ReactComponent as VISA } from "../../assets/images/cards/visa.svg";

const Card = ({ move, userInfo, shopInfo }) => {
  const fincode = useFincode(shopInfo.fincode_tenant_id)
  const { t } = useTranslation();

  const [cards, setCards] = useState([]);

  const fetchCard = async () => {
    if (userInfo.fincode_customer_id) {
      try {
        setCards(await fincode.listCard(userInfo.fincode_customer_id))
      } catch (e) {
        // console.log(e)
        alert(t('card.get_failure'))
      }
    }
  }

  const icon = (brand) => {
    switch (brand) {
      case "AMEX": return (<AMEX width="4rem" />)
      case "DINERS ": return (<DINERS width="4rem" />)
      case "JCB": return (<JCB width="4rem" />)
      case "MASTER": return (<MASTER width="4rem" />)
      case "VISA": return (<VISA width="4rem" />)
      default: return null
    }
  }

  useEffect(() => {
    fetchCard()
  }, [])

  const handleDelete = async (cardId) => {
    try {
      if (window.confirm(t('card.delete_confirm'))) {
        await api.delete(`/user/customers/cards/${cardId}`)
        alert(t('card.delete_success'))
        await fetchCard()
      }
    } catch (e) {
      // console.log(e)
      alert(t('card.delete_failure'))
    }
  }

  return (
    <>
      <div className="point-back">
        <button onClick={() => move('index')}>
        {t('card.back')}
        </button>
      </div>
      <div className="credit-card-list" >
      <div className="point-title">{t('card.card_list')}</div>
        <ul className="credit-card-list-ul">
          {cards.map((c, i) => (
            <li key={i} className="credit-card-list-li">
              <div className='card-brand'>
                {icon(c.brand)}
              </div>
              <div className='card-info'>
                <div>{c.card_no}</div>
                <div>{c.holder_name}</div>
                <div>{`${t(`card.expire`)} ${c.expire.slice(-2)}/20${c.expire.slice(0, 2)}`}</div>
              </div>
              <div className='card-delete'>
              <button type="button" onClick={() => handleDelete(c.id)}>{t('card.delete')}</button>
              </div>
            </li>
          ))}
        </ul>
        <button type="button" className="credit-card-new-button" onClick={() => move('card-edit')}>{t('card.register_card')}</button>
      </div>
    </>
  )
}

export default Card
