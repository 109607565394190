import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const lang = localStorage.getItem('language')

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    // fallbackLng: "ta",
    lng: lang ? lang : 'ta',
    // debug: true,
    ns: ['translations'],
    fallbackNS: 'translations',
    // detection: {
    //   order: ["localStorage", "navigator"],
    // },
    backend: {
      loadPath: "/languages/{{lng}}/{{ns}}.json",
    }
  });