import React from 'react'

import './Index.css'

import Banner from './Banner'
import Content from './Content'

const Index = ({move, shopInfo, userInfo}) => {

    return (
        <>
            {shopInfo.infoImgs && shopInfo.infoImgs.length > 0 &&
                <Banner shopInfo={shopInfo}/>
            }
            <Content move={move} shopInfo={shopInfo} userInfo={userInfo}/>
        </>
    )
}

export default Index