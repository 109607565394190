import React, { useEffect, useRef, useState } from 'react'

import './Gacha.css'

const GachaMovie = ({hitRank, skipGacha, shopInfo, packageInfo}) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        video.addEventListener('ended', skipGacha);
        return () => {
            video.removeEventListener('ended', skipGacha);
        };
    }, [skipGacha]);

    const originals = shopInfo.originalEffectPattern;

    const videoSrc = (rank) => {
        const effectPattern = packageInfo.effect_pattern ? packageInfo.effect_pattern : shopInfo.effectPattern;
        if (effectPattern <= 1000) {
            return `${process.env.REACT_APP_ASSET_URL}/static/gacha-effect/gacha_effect${effectPattern}-${rank}.mp4`;
        } else {
            if (originals) {
                const original = originals.find(o => o.id === Number(effectPattern));
                if (original) {
                const item = original.items.find(i => i.rank === rank);
                if (item) {
                    return `${process.env.REACT_APP_ASSET_URL}${item.path_with_version}`;
                }
                }
            }
        }
    }

    const src = videoSrc(hitRank);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    return (
        <>
            <div className="gacha-movie">
                <video ref={videoRef} src={src} autoPlay muted playsInline></video>
                <div className="gacha-movie-button">
                    <button onClick={skipGacha}>SKIP</button>
                </div>
            </div>
        </>
    )
}

export default GachaMovie;

