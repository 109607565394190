import { useState, useEffect } from 'react'
import { api } from '../../../lib/api'
import { useFincode } from '../../../lib/fincode'
import { useDebounce } from '../../hooks/useDebounce'
import { useTranslation } from "react-i18next";

import './User.css'

const CardEdit = ({ move, shopInfo }) => {
  const [ui, setUi] = useState(null);
  const { t } = useTranslation();
  const fincode = useFincode(shopInfo.fincode_tenant_id)


  useEffect(() => {
    const root = document.documentElement;
    const css = getComputedStyle(root);

    const backgroundColor = css.getPropertyValue('--mypage-color').replace('#', '');
    const colorText = css.getPropertyValue('--body-font-color').replace('#', '');
    const colorBackgroundInput = css.getPropertyValue('--login-form-color').replace('#', '');
    const colorCheck = css.getPropertyValue('--login-button-color').replace('#', '');
    const colorPlaceHolder = css.getPropertyValue('--login-form-placeholder-color').replace('#', '');

    const appearance = {
      hideLabel: false, hideHolderName: false, hidePayTimes: true,
      colorBackground: backgroundColor,
      colorBackgroundInput: colorBackgroundInput,
      colorText: colorText,
      colorPlaceHolder: colorPlaceHolder,
      colorLabelText: colorText,
      colorBorder: colorBackgroundInput,
      colorError: "c12424",
      colorCheck: colorCheck,
      colorRadio: colorCheck,
      colorRadioText: colorText,
      colorSelect: colorCheck,
      colorBackgroundRadio: colorBackgroundInput,
      labelCardNo: t('card_edit.card'), labelExpire: t('card_edit.expire'), labelCvc: t('card_edit.cvc'), labelHolderName: t('card_edit.holder'),
    }

    const width = parseInt(document.getElementById('content').offsetWidth * 0.90 - 30)

    setUi(fincode.ui("fincode", "cards", width, appearance))
  }, [])


  const handleAddCard = useDebounce({
    callback: async () => {
      try {
        const {
          cardNo: card_no,
          expire,
          holderName: holder_name,
          CVC: security_code,
        } = await ui.getFormData()

        const date = new Date()
        if (`${String(date.getFullYear()).slice(2)}${String(date.getMonth() + 1).padStart(2, '0')}` > expire)
          throw new Error(t('card_edit.expire_error'))


        const customer = await api.post('/user/customers')

        await fincode.cards({
          customer_id: customer.id,
          default_flag: '1',
          card_no,
          expire,
          holder_name,
          security_code
        })

        alert(t('card_edit.register_success'))
        move('card')
      } catch (e) {
        // console.log(e)
        alert(e?.message || t('card_edit.register_failure'));
      }
    },
    delay: 1_000
  })

  return (
    <>
      <div>
        <div className="point-back">
          <button onClick={() => move('card')}>
          {t('card_edit.back')}
          </button>
        </div>
        <div className="point-title">{t('card_edit.title')}</div>
        <div id="fincode-wrap">
          <form id="fincode-form">
            <div className="clearfix padding-payment"></div>
            <div id="fincode"></div>
            <div className='payment-button'>
              <button type="button" onClick={handleAddCard} class="fincode-button">
              <span>{t('card_edit.register_card')}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default CardEdit
