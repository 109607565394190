import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'

import {AddressBackContext} from '../../../provider/AddressBackProvider'

import './User.css'

import GachaWonCard from '../Gacha/GachaWonCard'
import GachaWonChange from '../Gacha/GachaWonChange'
import { useTranslation } from "react-i18next";

const Hold = ({move, userInfo, setUserInfo, shopPath, gachaWon, shopInfo, setShopInfo}) => {
    const { t } = useTranslation();
    const [selectedCards, setSelectedCards] = useState([]);
    
    const handleSelect = (cardId) => {
        if (selectedCards.includes(cardId)) {
            setSelectedCards(selectedCards.filter((id) => id !== cardId));
        } else {
            setSelectedCards([...selectedCards, cardId]);
        }
    }

    const {addressBack, setAddressBack} = useContext(AddressBackContext);

    const moveAdress = () => {
        setAddressBack(['onhold']);
        move('address')
    }

    const isSelected = (cardId) => {
        return selectedCards.includes(cardId);
    }
    const [cardList, setCardList] = useState([]);

    const [selectedHoldMenu, setSelectedHoldMenu] = useState(0);

    useEffect(() => {
        setCardList([]);    
        setSelectedCards([]);
        setNocardText(t('common.loading'));

        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }
        fetch(`${process.env.REACT_APP_API_URL}/api/package/hit-cards?status=${selectedHoldMenu}`, {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            if(!data.errors) {
                // data.hitsのrankを昇順,　次にnameを昇順に並び替える, 次にhold_dateが古い順に並び替える
                // data.hits.sort((a, b) => {
                //     if (a.rank < b.rank) return -1;
                //     if (a.rank > b.rank) return 1;
                //     if (a.name < b.name) return -1;
                //     if (a.name > b.name) return 1;
                //     if (a.hold_date < b.hold_date) return -1;
                //     if (a.hold_date > b.hold_date) return 1;
                //     return 0;
                // });
                setCardList(data.hits);
                setShopInfo({...shopInfo, shipFee: data.ship_fee});
            } else {
                alert(Object.values(data.errors)[0]);
            }
            setNocardText(t('hold.error'));
        })
        .catch(error => {
            setNocardText(t('hold.error'));
            console.error(error);
        });
    }, [selectedHoldMenu]);

    const CreateCardList = () => {
        return cardList.map((card, i) => {
            if (selectedHoldMenu == 0) {
                return <GachaWonCard moveAdress={()=>{}} card={card} handleSelect={handleSelect} isSelected={isSelected} shopInfo={shopInfo} key={i} menu={0}/>
            }
            if (selectedHoldMenu == 1) {
                return <GachaWonCard moveAdress={()=>{}} card={card} key={i} menu={1}/>
            }
            if (selectedHoldMenu == 2) {
                return <GachaWonCard moveAdress={()=>{}} card={card} key={i} menu={2}/>
            }
        })
    }

    const [nocardText, setNocardText] = useState(t('common.loading'));

    return (
        <>
            <div className="hold">
                <ul className="hold-menus">
                    <li className={selectedHoldMenu == 0 ? "hold-menu hold-menu-selected" : "hold-menu"} onClick={() => setSelectedHoldMenu(0)}>{t('hold.keep')}</li>
                    <li className={selectedHoldMenu == 1 ? "hold-menu hold-menu-selected" : "hold-menu"} onClick={() => setSelectedHoldMenu(1)}>{t('hold.prepara')}</li>
                    <li className={selectedHoldMenu == 2 ? "hold-menu hold-menu-selected" : "hold-menu"} onClick={() => setSelectedHoldMenu(2)}>{t('hold.delivered')}</li>
                </ul>
                {cardList.length == 0 ? <div className="no-card">{nocardText}</div>
                :
                    <>
                        <div className="gacha-won-cards-note">{t('hold.note')}</div>
                        <div className="gacha-won-cards">
                            <CreateCardList />
                        </div>      
                    </>
                }
            </div>
            {selectedHoldMenu == 0 &&
                <>
                <GachaWonChange move={move} cardList={cardList} selectedCards={selectedCards} setSelectedCards={setSelectedCards} moveAdress={moveAdress} userInfo={userInfo} setUserInfo={setUserInfo} shopPath={shopPath} shopInfo={shopInfo} setShopInfo={setShopInfo}/>
                <div className="footer-dammy"></div>
                </>
            }
            {selectedHoldMenu !== 0 &&
            <footer>
            <div className="footer-1">
            <div className="footer-company">
              <a href={shopInfo.url} 
              target="_blank">{t('common.company')}</a>
            </div>
            <div className="footer-policy">
                <a href={`${shopPath}/terms-of-service`}
                target="_blank">{t('common.service')}</a>
            </div>
            <div className="footer-law">
                <a href={`${shopPath}/special-commercial-transaction-law`}
                    target="_blank">{t('common.law')}</a>
            </div>
            </div>
            <div className="footer-2">
            <div className="footer-privacy">
                <a href={`${shopPath}/privacy-policy`}
                target="_blank">{t('common.privacy')}</a>
            </div>
            <div className="footer-qa">
                <a href={`${shopPath}/`}
                target="_blank">{t('common.contact')}</a>
            </div>
            </div>
            <div className="footer-shop">
                <div className="footer-logo">
                    <img src={shopInfo.logoUrl} alt="logo" onClick={e => move('index')} />
                </div>
                <div className="footer-name">
                    Powered by {shopInfo.name}
                </div>
            </div>
        </footer>
        }
        </>

    )
}

export default Hold
