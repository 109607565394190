import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from "react-i18next";

import './Login.css'

const Login = ({move, shopInfo, setIsLogined, setSessionToken, shopPath}) => {
    const { t,i18n } = useTranslation();
    const passwordReset = (e) => {
        e.preventDefault();
        move('passwordReset')
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = (e) => {
        if (loginButton == t('login.loging')) {
            return;
        }

        setLoginButton(t('login.loging'));
        fetch(process.env.REACT_APP_API_URL + '/api/login', {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                shop_id: shopInfo.id,
                email: email,
                password: password,
            }),
        }).then((response) => response.json())
        .then((data) => {
            if (data.token) {
                setIsLogined(true);
                setSessionToken(data.token);
                document.cookie = 'token=' + data.token + ';max-age=946080000;path=/';
                window.location.reload();
            } else {
                setLoginButton(t('login.title'));
                alert(data.message);
                return;
            }
        }).catch((error) => {
            setLoginButton(t('login.title'));
            alert(t('common.wait'));
        });
    }

    const [loginButton, setLoginButton] = useState(t('login.title'));
    
    useEffect(() => {
        // 言語が変更されたときにloginButtonの状態を更新
        const changeLanguage = () => {
            setLoginButton(t('login.title'));
        };

        // 言語が変更されたときにchangeLanguageを実行
        i18n.on('languageChanged', changeLanguage);

        // コンポーネントがアンマウントされたときにイベントリスナーを削除
        return () => {
            i18n.off('languageChanged', changeLanguage);
        };
    }, [t, i18n]);

    useEffect(() => {
        let shopName = shopInfo.name;
        document.title = 'ログイン | ' + shopName;
    } ,[]);

    return (
        <>
            <div className="login">
                <div className="login-title">{t('login.title')}</div>
                <div className="login-form">
                    <dl>
                        <dt>{t('login.email')}</dt>
                        <dd><input type="text" placeholder={t('login.email')} onChange={(e) => {setEmail(e.target.value)}}/></dd>
                        <dt>{t('login.password')}</dt>
                        <dd><input type="password" placeholder={t('login.password')}  onChange={(e) => {setPassword(e.target.value)}}/></dd>
                    </dl>

                    <div className="login-forgot-password">
                        <a href="" onClick={passwordReset}>{t('login.forgot_password')}</a>
                    </div>
                    <div className="login-button">
                        <button onClick={login}>{loginButton}</button>
                    </div>

                    <hr></hr>

                    <div className="login-new-register">
                    <button onClick={() => {move('register')}}>{t('login.new_register')}</button>
                </div>
                </div>
            </div>
            {/* <div className="login-footer">
                <div className="login-footer-policy">
                    <a href={`${shopPath}/terms-of-service`}
                    target="_blank">{t('common.service')}</a>
                </div>
                <div className="login-footer-privacy">
                    <a href={`${shopPath}/privacy-policy`}
                    target="_blank">{t('common.privacy')}</a>
                </div>
                
                <div className="login-footer-law">
                    <a href={`${shopPath}/special-commercial-transaction-law`}
                        target="_blank">{t('common.law')}</a>
                </div>
                <div className="clearfix"></div>
            </div> */}
        </>
    )
}

export default Login