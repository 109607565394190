import React from 'react'
import { createContext, useState } from 'react'

export const AddressBackContext = createContext();

export const AddressBackProvider = ({children}) => {
    const [addressBack, setAddressBack] = useState('');
    const addressBackProviderValue = {
        addressBack,
        setAddressBack
    }

    return (<AddressBackContext.Provider value={addressBackProviderValue}>{children}</AddressBackContext.Provider>)
}