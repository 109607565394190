import React from 'react'
import { useState } from 'react'

import './Point.css'

const ShopCoupon = ( {shopInfo, userInfo, setShopPaymentResult, setShopPaymentMessage} ) => {

    const [code, setCode] = useState('');
    const [processing, setProcessing] = useState(false);

    // 発行ボタン押下時
    const getCouponPoint = (e) => {

        if (processing) {
            return;
        }

        setProcessing(true);
        //let value = e.target.value;
        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/shoppayment/${shopInfo.id}/add-point-manual`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                code: code,
                shop_id: shopInfo.id,
                user_id: userInfo.id,
            })
        })
        .then(response => response.json())
        .then(data => {

            setShopPaymentMessage(data.message)
            if (data.hasError) {
                setShopPaymentResult('error');
            } else {
                window.history.pushState(null,null,"/");
                window.location.href = data.success_url;
            }
            setProcessing(false);
            
        })
        .catch(error => {
            setProcessing(false);
            alert("エラーが発生しました。");
            //e.target.innerHTML = "¥ " + value;
        });

    }


    return (
        <>
            <div className='shop-coupon'>
                <div className='shop-coupon-title'>ポイント発行方法</div>
                <div className='shop-coupon-instruction'>
                    店舗で決済後、店舗から提示されたクーポンコードを入力し、発行ボタンを押してください。
                </div>
                <div>
                    <input className='shop-coupon-input' type="text" value={code} placeholder='12桁のコードを入力してください'
                        onChange={(event) => setCode(event.target.value)} />
                </div>
                <div className='shop-coupon-bottom'>
                    <button className="shop-coupon-use-button" disabled={ processing }
                        onClick={getCouponPoint} >{ ( processing ? '発行中...' : '発行' )}</button>
                </div>
            </div>

            {/* <div>
                <div>ポイント決済方法②</div>
                <div>
                    店舗で決済後、店舗から提示されたQRコードをカメラで読み込んでください。
                </div>
                <div><QRCode value={couponInfo.coupon_code}/></div>
            </div> */}

        </>
    )
}

export default ShopCoupon