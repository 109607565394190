// カードを新規追加するモーダルのコンポーネント

import React, { useState } from 'react';

import './PointChange.css';

// React Modalで実装
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";

const GachaStart = ({modalIsOpen, changeModalIsOpen, point, times, gacha, gachaButton}) => {
    const { t } = useTranslation();

    const [isChanged, setIsChanged] = useState(false);

    const closeModal = () => {
        changeModalIsOpen(false);
    }
    
    Modal.setAppElement('#root');

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                className="point-change-modal"
                overlayClassName="point-change-modal-overlay"
                contentLabel="Common Modal"
            >
                <div className="point-change-modal-content">
                    <>
                        <div className="point-change-modal-title">{t('gacha_start.title')}</div>
                        <div className="point-change-modal-text">{t('gacha_start.text1')}{point}{t('gacha_start.text2')}{times}{t('gacha_start.text3')}</div>
                        <div className="point-change-modal-button">
                            <button className="point-change-modal-button-charge" onClick={gacha}>{gachaButton}</button>
                            <button className="point-change-modal-button-close" onClick={closeModal}>{t('gacha_start.close')}</button>
                        </div>
                    </>
                </div>
            </Modal>
        </>
    );
}

export default GachaStart;