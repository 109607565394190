import React from 'react'
import Modal from 'react-modal'
import { useContext, useEffect } from 'react'
import { useState } from 'react'
import i18n from 'i18next';

import './User.css'

import { AddressBackContext } from '../../../provider/AddressBackProvider'
import { useTranslation } from "react-i18next";

const Mypage = ({ modalIsOpen, closeModal, move, router, shopInfo, userInfo, shopPath }) => {
  const { t } = useTranslation();
  const { addressBack, setAddressBack } = useContext(AddressBackContext);

  const lang = localStorage.getItem('language')
  const [language, setLanguage] = useState()

  useEffect(() => {
    setAddressBack('')
  }, [])

  useEffect(() => {
    let default_country = shopInfo.defaultCountry

    if (default_country == '' ) {
        default_country = 0
    }

    if (lang  == '' || lang == 'undefined') {
        setLanguage('ja')
        i18n.changeLanguage('ja', (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            t('key'); // -> same as i18next.t
        });
        localStorage.setItem('language', 'ja');
        setLanguage('ja')
    } else {
        if (default_country == 0) {
            setLanguage('ja')
            i18n.changeLanguage('ja', (err, t) => {
                if (err) return console.log('something went wrong loading', err);
                t('key'); // -> same as i18next.t
            });
            localStorage.setItem('language', 'ja');
            setLanguage('ja')
        } else if (default_country == 1) {
            setLanguage('ta')
            i18n.changeLanguage('ta', (err, t) => {
                if (err) return console.log('something went wrong loading', err);
                t('key'); // -> same as i18next.t
            });
            localStorage.setItem('language', 'ta');
            setLanguage('ta')
        } else if (default_country == 2) {
            setLanguage('ta')
            i18n.changeLanguage('ta', (err, t) => {
                if (err) return console.log('something went wrong loading', err);
                t('key'); // -> same as i18next.t
            });
            localStorage.setItem('language', 'ta');
            setLanguage('ta')
        } else if (default_country == 3) {
            setLanguage('en')
            i18n.changeLanguage('en', (err, t) => {
                if (err) return console.log('something went wrong loading', err);
                t('key'); // -> same as i18next.t
            });
            localStorage.setItem('language', 'en');
            setLanguage('en')
        }
    }
    
  }, [shopInfo])

  useEffect(() => {
      localStorage.setItem('language', language)

      if (language == 'ta') {
      i18n.changeLanguage('ta', (err, t) => {
          if (err) return console.log('something went wrong loading', err);
          t('key'); // -> same as i18next.t
      });
      } else if (language == 'ja') {
      i18n.changeLanguage('ja', (err, t) => {
          if (err) return console.log('something went wrong loading', err);
          t('key'); // -> same as i18next.t
      });
      } else if (language == 'en') {
      i18n.changeLanguage('en', (err, t) => {
          if (err) return console.log('something went wrong loading', err);
          t('key'); // -> same as i18next.t
      });
      }
  },[language])

  const moveAdress = () => {
    closeModal()
    setAddressBack(['mypage', router[0], router[1]])
    move('address')
  }

  const movePoint = e => {
    closeModal()
    move('point')
  }

  const moveHold = e => {
    closeModal()
    move('onhold')
  }

  const moveCard = e => {
    closeModal()
    move('card')
  }

  const [addressInfo, setAddressInfo] = useState({
    last_name: '',
    first_name: '',
    postal_code: '',
    prefecture: '',
    address: '',
  })

  const Logout = () => {
    let token = document.cookie.split('; ').find(row => row.startsWith('token'));
    if (token) {
      token = token.split('=')[1]
    }
    fetch(process.env.REACT_APP_API_URL + '/api/logout', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${decodeURIComponent(token)}`,
        'Accept': 'application/json',
      },
      credentials: 'include',
    })
      .then(response => {
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.reload()
      })
      .catch(error => {
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.reload()
      });
  }

  useEffect(() => {
      setAddressText(t('common.loading'))
      
      let token = document.cookie.split('; ').find(row => row.startsWith('token'));
      if (token) {
          token = token.split('=')[1]
      }
      fetch(process.env.REACT_APP_API_URL + '/api/address/default', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${decodeURIComponent(token)}`,
              'Accept': 'application/json',
          },
          credentials: 'include',
      })
      .then(response => {
          if (response.status == 200) {
              return response.json()
          } else {
              throw new Error('Failed to get default address')
          }
      })
      .then(data => {
          if (data.user_address)
              setAddressInfo(data.user_address)
          else
              setAddressText(t('mypage.error'))
      })
      .catch(error => {
          setAddressText(t('mypage.error'))
          console.log(error)
      });
  }, [modalIsOpen])

  const [addressText, setAddressText] = useState(t('common.loading'))

  Modal.setAppElement('#root');

  return (
      <>
          <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              className="mypage-modal"
              overlayClassName="mypage-modal-overlay"
              contentLabel="Mypage Modal"
          >
              <div className="mypage">
                  <div className="mypage-title">{t('mypage.title')}</div>
                  <div className='mypage-close-icon' onClick={closeModal}>×</div>
                  <div className='clearfix'></div>
                  <div className='line'></div>
                  <div className="language-change"> 
                    <div className="language-image">
                        <img src="/img/language-outline.svg" alt="language"/>
                    </div>  
                    <div class="btn-group">
                        <button type="button" class="btn btn-default dropdown-toggle maypage-language-change-button" data-toggle="dropdown" aria-expanded="false">
                            Language
                        </button>
                        <ul class="dropdown-menu" role="menu" >
                            <li onClick={(e) => setLanguage("ta")}>
                                <a class="dropdown-item" href="#">
                                    {language == 'ta' ? <img src="/img/check.svg" alt="language"/>: <span style={{marginRight: '1.5em'}}></span>}
                                    繁体
                                </a>
                            </li>
                            <li onClick={(e) => setLanguage("ja")}>
                                <a class="dropdown-item" href="#">
                                    {language == 'ja' ? <img src="/img/check.svg" alt="language"/>: <span style={{marginRight: '1.5em'}}></span>}
                                    日本語
                                </a>
                            </li>
                            <li onClick={(e) => setLanguage("en")}>
                                <a class="dropdown-item" href="#">
                                    {language == 'en' ? <img src="/img/check.svg" alt="language"/>: <span style={{marginRight: '1.5em'}}></span>}
                                    English
                                </a>
                            </li>
                        </ul>
                    </div>
                  </div>
                  {/* <div className="user-info">
                      {t('mypage.info')}: <div className="user-info-logout" onClick={Logout}>{t('mypage.logout')}</div>
                      {userInfo.email}
                  </div> */}
                  <div className="mypage-balance">
                    <div className="mypage-balance-title">{t('mypage.balance')}</div>
                    <div className="mypage-balance-point">
                        <img className="mypage-balance-point-icon" src="/img/balance.svg" />{userInfo.point} pt<br />
                    </div>
                    { userInfo.expired_date &&
                        <div className="mypage-balance-expire">
                            ※{userInfo.expired_date}{t('mypage.expire1')} {userInfo.expired_point}pt {t('mypage.expire2')}
                        </div>
                    }
                    <div className="mypage-balance-button">
                        <div className='maypage-line'></div>
                        <button className="mypage-balance-buy-button" onClick={movePoint}>{t('mypage.buy')}</button>
                        <button className="mypage-confirm-bought-button" onClick={moveCard}>{t('mypage.payment')}</button>
                    </div>
                  </div>

                  <div className="mypage-ship" onClick={moveAdress}>
                      <div className="mypage-ship-title">{t('mypage.ship')}</div>
                      { addressInfo.last_name ? (
                          <>
                              <div className="mypage-ship-info">
                                  <div className="mypage-ship-info-name">{addressInfo.last_name} {addressInfo.first_name}</div>
                                  {addressInfo.address.postal_code != '0000000' &&
                                    <div className="mypage-ship-info-postcode">〒{addressInfo.address.postal_code}</div>
                                  }
                                  <div className="mypage-ship-info-address">{addressInfo.address.address}</div>
                              </div>
                          </>
                      ) : (
                          <>
                              <div className="mypage-ship-info">
                                  <div className="mypage-ship-info-name">{addressText}</div>
                              </div>
                          </>
                      )}
                  </div>
                  
                  <div className="mypage-balance-button">
                    <button className="mypage-confirm-bought-button" onClick={moveHold}>{t('mypage.confirm')}</button>
                  </div>
                  <div className="user-info-logout" onClick={Logout}>{t('mypage.logout')}</div>
                  
                  <div className="mypage-footer">
                      <div className="mypage-footer-policy">
                          <a href={`${shopPath}/terms-of-service`}
                            target="_blank">{t('common.service')}</a>
                      </div>
                      <div className="mypage-footer-privacy">
                          <a href={`${shopPath}/privacy-policy`}
                            target="_blank">{t('common.privacy')}</a>
                      </div>
                      <div className="mypage-footer-law">
                          <a href={`${shopPath}/special-commercial-transaction-law`}
                                target="_blank">{t('common.law')}</a>
                      </div>
                  </div>
        </div>
      </Modal>
    </>
  )
}

export default Mypage
