import React from 'react'
import { useState, useContext } from 'react'
import { useEffect } from 'react'

import './Gacha.css'

import GachaWonCard from './GachaWonCard'
import GachaWonChange from './GachaWonChange'

import {AddressBackContext} from '../../../provider/AddressBackProvider'
import { useTranslation } from "react-i18next";

const GachaWon = ({move, id, userInfo, setUserInfo, shopPath, shopInfo, setShopInfo}) => {
    const { t } = useTranslation();

    let packageId = null;

    const {addressBack, setAddressBack} = useContext(AddressBackContext);

    const moveAdress = () => {
        setAddressBack(['gacha-won', id])
        move('address')
    }

    const [selectedCards, setSelectedCards] = useState([]);
    
    const handleSelect = (cardId) => {
        if (selectedCards.includes(cardId)) {
            setSelectedCards(selectedCards.filter((id) => id !== cardId));
        } else {
            setSelectedCards([...selectedCards, cardId]);
        }
    }

    const isSelected = (cardId) => {
        return selectedCards.includes(cardId);
    }
    const [cardList, setCardList] = useState([]);

    useEffect(() => {
        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }
        fetch(`${process.env.REACT_APP_API_URL}/api/package/hit-cards/${id}`, {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            if(!data.errors) {
                setCardList(data.hits);
                packageId = data.hits[0].package_id;
                setShopInfo({...shopInfo, shipFee: data.ship_fee});
                window.scrollTo(0, 0);
            } else {
                alert(Object.values(data.errors)[0]);
            }
        })
        .catch(error => {
            console.error(error);
        });
    }, []);

    const CreateCardList = () => {
        return cardList.map((card, i) => {
            return <GachaWonCard card={card} handleSelect={handleSelect} isSelected={isSelected} shopInfo={shopInfo} key={i} />
        })
    }

    return (
        <>
            <div className="gacha-won">
                <div className="gacha-won-title">{t('gacha_won.title')}</div>
                <div className="gacha-won-description">{t('gacha_won.description')}</div>
                <div className="gacha-won-note">{t('gacha_won.note')}</div>

                <div className="gacha-won-cards">
                    {cardList.length > 0 ?
                        <CreateCardList />
                        :
                        <div className="gacha-won-loading">{t('gacha_won.loading')}</div>
                    }
                </div>      
            </div>
            <GachaWonChange moveAdress={moveAdress} move={move} cardList={cardList} selectedCards={selectedCards} setSelectedCards={setSelectedCards} id={id} userInfo={userInfo} setUserInfo={setUserInfo} shopPath={shopPath} gachaWon={true} shopInfo={shopInfo} setShopInfo={setShopInfo}/>
            <div className="footer-dammy"></div>
        </>
    )
}

export default GachaWon