import React from 'react'
import { Link, useParams } from 'react-router-dom'
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { useEffect } from 'react'
import { useState } from 'react'

import Mypage from '../User/Mypage'

import './Header.css'

const Header = ({isLogined, move, router, mypage, shopInfo, userInfo}) => {

    const {shop} = useParams();
    
    const login = e => {
        move('login')
    }

    const buyPoint = e => {
        move('point')
    }

    const lang = localStorage.getItem('language')
    const [language, setLanguage] = useState()
    const { t } = useTranslation();

    useEffect(() => {
        let default_country = shopInfo.defaultCountry

        if (default_country == '' ) {
            default_country = 0
        }

        if (lang  == '' || lang == 'undefined') {
            setLanguage('ja')
            i18n.changeLanguage('ja', (err, t) => {
                if (err) return console.log('something went wrong loading', err);
                t('key'); // -> same as i18next.t
            });
            localStorage.setItem('language', 'ja');
            setLanguage('ja')
        } else {
            if (default_country == 0) {
                setLanguage('ja')
                i18n.changeLanguage('ja', (err, t) => {
                    if (err) return console.log('something went wrong loading', err);
                    t('key'); // -> same as i18next.t
                });
                localStorage.setItem('language', 'ja');
                setLanguage('ja')
            } else if (default_country == 1) {
                setLanguage('ta')
                i18n.changeLanguage('ta', (err, t) => {
                    if (err) return console.log('something went wrong loading', err);
                    t('key'); // -> same as i18next.t
                });
                localStorage.setItem('language', 'ta');
                setLanguage('ta')
            } else if (default_country == 2) {
                setLanguage('ta')
                i18n.changeLanguage('ta', (err, t) => {
                    if (err) return console.log('something went wrong loading', err);
                    t('key'); // -> same as i18next.t
                });
                localStorage.setItem('language', 'ta');
                setLanguage('ta')
            } else if (default_country == 3) {
                setLanguage('en')
                i18n.changeLanguage('en', (err, t) => {
                    if (err) return console.log('something went wrong loading', err);
                    t('key'); // -> same as i18next.t
                });
                localStorage.setItem('language', 'en');
                setLanguage('en')
            }
        }
        
    }, [shopInfo])

    useEffect(() => {
        localStorage.setItem('language', language)

        if (language == 'ta') {
        i18n.changeLanguage('ta', (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            t('key'); // -> same as i18next.t
        });
        } else if (language == 'ja') {
        i18n.changeLanguage('ja', (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            t('key'); // -> same as i18next.t
        });
        } else if (language == 'en') {
        i18n.changeLanguage('en', (err, t) => {
            if (err) return console.log('something went wrong loading', err);
            t('key'); // -> same as i18next.t
        });
        }
    },[language])

    return (
        <>
            <header>
                {!isLogined &&
                    <>
                    <div className="language-change"> 
                        <div className="language-image">
                            <img src="/img/language-outline.svg" alt="language"/>
                        </div>  
                        <div class="btn-group">
                            <button type="button" class="btn btn-default dropdown-toggle language-change-button" data-toggle="dropdown" aria-expanded="false">
                                Language
                            </button>
                            <ul class="dropdown-menu" role="menu" >
                                <li onClick={(e) => setLanguage("ta")}>
                                    <a class="dropdown-item" href="#">
                                        {language == 'ta' ? <img src="/img/check.svg" alt="language"/>: <span style={{marginRight: '1.5em'}}></span>}
                                        繁体
                                    </a>
                                </li>
                                <li onClick={(e) => setLanguage("ja")}>
                                    <a class="dropdown-item" href="#">
                                        {language == 'ja' ? <img src="/img/check.svg" alt="language"/>: <span style={{marginRight: '1.5em'}}></span>}
                                        日本語
                                    </a>
                                </li>
                                <li onClick={(e) => setLanguage("en")}>
                                    <a class="dropdown-item" href="#">
                                        {language == 'en' ? <img src="/img/check.svg" alt="language"/>: <span style={{marginRight: '1.5em'}}></span>}
                                        English
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="logo">
                        <img src={shopInfo.logoUrl} alt="logo" onClick={e => move('index')} />
                    </div>
                    {router[0] !== 'login' &&
                        <div className="login-user">
                            {/* <img src="/img/user.svg" alt="user"  onClick={login}/> */}
                            <button class="user-button"  onClick={login}>
                                {t('login.register')}/{t('login.title')}
                            </button>
                        </div>
                    }
                    </>
                }
                {isLogined &&
                    <>
                        <div className="balance" onClick={buyPoint}>
                            <div className="balance-pt">
                                <div className="balance-icon">
                                    <img src="/img/balance.svg" alt="balance" />
                                </div>
                                <span className="balance-pt-value">{userInfo.point.toLocaleString()}</span> pt
                            </div>
                        </div>
                        <div className="logo">
                            <img src={shopInfo.logoUrl} alt="logo" onClick={e => move('index')} />
                        </div>
                        <div className="user">
                            <img src="/img/user.svg" alt="user"  onClick={mypage}/>
                        </div>
                    </>
                }
            </header>
            {/* <div className="language-change">
                <select className="language-select" value={language} onChange={(e) => setLanguage(e.target.value)}>
                    <option value="ta">🌐 繁体</option>
                    <option value="ja">🌐 日本語</option>
                    <option value="en">🌐 English</option>
                </select>
            </div> */}
        </>
    )
}

export default Header