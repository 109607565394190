// カードを新規追加するモーダルのコンポーネント

import React, { useState } from 'react';

import './BuyPointConfirm.css';

// React Modalで実装
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";
const BuyPointConfirm = ({modalIsOpen, changeModalIsOpen, buyPoint}) => {
    const { t } = useTranslation();

    const closeModal = () => {
        changeModalIsOpen(false);
    }

    const charge = () => {
        buyPoint();
        closeModal();
    }

    Modal.setAppElement('#root');
    
    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                className="buy-point-confirm-modal"
                overlayClassName="buy-point-confirm-modal-overlay"
                contentLabel="Common Modal"
            >
                <div className="buy-point-confirm-modal-content">
                    <div className="buy-point-confirm-modal-title">{t('buy_point_confirm.title')}</div>
                    <div className="buy-point-confirm-modal-text">
                        {t('buy_point_confirm.text1')}<br />
                        {t('buy_point_confirm.text2')}<br />
                    </div>
                    <div className="buy-point-confirm-modal-button">
                        <button className="buy-point-confirm-modal-button-charge" onClick={charge}>{t('buy_point_confirm.charge')}</button>
                        <button className="buy-point-confirm-modal-button-close" onClick={closeModal}>{t('buy_point_confirm.close')}</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default BuyPointConfirm;