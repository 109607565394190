import React from 'react'

import './User.css'
import { useTranslation } from "react-i18next";

const Policy = ({subPath, shopInfo}) => {
    const { t } = useTranslation();
    console.log('subPath', subPath)
    console.log('shopInfo', shopInfo)
    return (
        <>
            <div className="policy-contents">
                {subPath === 'privacy-policy' ? (
                    <>
                        <h1>{t('common.service')}</h1>
                        <div className="policy-content" style={{whiteSpace: 'pre-line'}}>
                            {shopInfo.privacyPolicy}
                        </div>
                    </>
                ) : 
                subPath === 'terms-of-service' ? (
                    <>
                        <h1>{t('common.privacy')}</h1>
                        <div className="policy-content" style={{whiteSpace: 'pre-line'}}>
                            {shopInfo.termsOfService}
                        </div>
                    </>
                ) :
                subPath === 'special-commercial-transaction-law' ? (
                    <>
                        <h1>{t('common.law')}</h1>
                        <div className="policy-content" style={{whiteSpace: 'pre-line'}}>
                            {shopInfo.specialCommercialTransactionLaw}
                        </div>
                    </>
                ) : null}
            </div>
        </>
    )
}

export default Policy
