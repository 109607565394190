import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import fetchJsonp from 'fetch-jsonp'

import {AddressBackContext} from '../../../provider/AddressBackProvider'

import './User.css'
import { useTranslation } from "react-i18next";

const AddressEdit = ({move, id, mypage}) => {
    const { t } = useTranslation();
    const [userAddressInfo, setUserAddressInfo] = useState({
        last_name: '',
        first_name: '',
        last_name_kana: '',
        first_name_kana: '',
        postal_code: '',
        prefecture: '東京都',
        address: '',
        city: '',
        address2: '',
        phone_number: '',
    });

    const [registeredPostCode, setRegisteredPostCode] = useState('');

    const prefectures = [
        '北海道', '青森県', '岩手県', '宮城県', '秋田県',
        '山形県', '福島県', '茨城県', '栃木県', '群馬県',
        '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県',
        '富山県', '石川県', '福井県', '山梨県', '長野県',
        '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県',
        '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
        '鳥取県', '島根県', '岡山県', '広島県', '山口県',
        '徳島県', '香川県', '愛媛県', '高知県', '福岡県',
        '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県',
        '鹿児島県', '沖縄県'
    ];

    const {addressBack, setAddressBack} = useContext(AddressBackContext);

    const moveBackPage = () => {
        move('address')
    }

    const selectAddress = (e) => {
        const address = e.currentTarget
        const radio = address.querySelector('input[type="radio"]')
        radio.checked = true
    }

    const CreateSelectPrefecture = () => {
        return prefectures.map((prefecture, index) => {
            return (
                <option value={prefecture} key={index} onChange={(e) => setUserAddressInfo({...userAddressInfo, prefecture: e.target.value})}>{prefecture}</option>
            )
        })
    }

    const saveHandler = (e) => {
        if(saveButton == t('address_edit.saving')) {
            return
        }

        e.preventDefault()
        // console.log(userAddressInfo)
        setSaveButton(t('address_edit.saving'))

        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }
        
        let url = '/api/address/register';
        if (id) {
            url = '/api/address/edit/' + id;
        }

        fetch(process.env.REACT_APP_API_URL + url, {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(userAddressInfo)
        })
        .then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                throw new Error(t('address_edit.error'))
            }
        })
        .then(data => {
            console.log(data)
            setSaveButton(t('address_edit.save'))
            move('address')
        })
        .catch(error => {
            alert(t('common.error'))
            setSaveButton(t('address_edit.save'))
        });
    }

    useEffect(() => {
        if (userAddressInfo.country == 0) {
            setUserAddressInfo({
                ...userAddressInfo,
                address: '日本, ' + userAddressInfo.prefecture + userAddressInfo.city + userAddressInfo.address2,
            });
        } else {
            let countryName = 'jp';
            if (userAddressInfo.country == 1) {
                countryName = 'tw'
            }
            if (userAddressInfo.country == 2) {
                countryName = 'hk'
            }
            if (userAddressInfo.country == 3) {
                countryName = 'sg'
            }

            setUserAddressInfo({
                ...userAddressInfo,
                last_name_kana: userAddressInfo.last_name,
                first_name_kana: userAddressInfo.first_name,
                address: userAddressInfo.address2 + ' ,' + userAddressInfo.city + ' ,' + t('address_edit.country_' + countryName),
            });
        }
    }, [userAddressInfo.prefecture, userAddressInfo.city, userAddressInfo.address2, userAddressInfo.country, userAddressInfo.last_name, userAddressInfo.first_name])

    useEffect(() => {
        const postCode = userAddressInfo.postal_code;
        if (postCode.length == 7 && registeredPostCode != postCode && userAddressInfo.country == 0) {
            setRegisteredPostCode(postCode)
            let api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=';
            let address = document.getElementById('address');

            let url = api + postCode;
            
            fetchJsonp(url, {
                timeout: 10000, //タイムアウト時間
            })
            .then((response)=>{
                return response.json();  
            })
            .then((data)=>{
                if(data.status === 400){ //エラー時
                    
                }else if(data.results === null){
                    
                } else {
                    setUserAddressInfo({
                        ...userAddressInfo,
                        prefecture: data.results[0].address1,
                        city: data.results[0].address2,
                        address2: data.results[0].address3,
                        address: data.results[0].address2 + data.results[0].address3,
                    })
                }
            })
            .catch((ex)=>{ //例外処理
                console.log(ex);
            });
        }
    }, [userAddressInfo.postal_code])

    useEffect(() => {
        if (id) {
            let token = document.cookie.split('; ').find(row => row.startsWith('token'));
            if (token) {
                token = token.split('=')[1]
            }
            fetch(process.env.REACT_APP_API_URL + '/api/address/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${decodeURIComponent(token)}`,
                    'Accept': 'application/json',
                },
                credentials: 'include',
            })
            .then(response => {
                if (response.status == 200) {
                    return response.json()
                } else {
                    throw new Error(t('address_edit.error'))
                }
            })
            .then(data => {
                setUserAddressInfo({
                    last_name: data.user_address.last_name,
                    first_name: data.user_address.first_name,
                    last_name_kana: data.user_address.last_name_kana,
                    first_name_kana: data.user_address.first_name_kana,
                    postal_code: data.user_address.address.postal_code,
                    prefecture: data.user_address.address.prefecture,
                    address: data.user_address.address.address,
                    city: data.user_address.address.city,
                    address2: data.user_address.address.address2,
                    phone_number: data.user_address.address.phone_number,
                })
                setLoading(false)
                setRegisteredPostCode(data.user_address.address.postal_code)
            })
            .catch(error => {
                alert(t('common.error'))
                move('address')
            });
        } else {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (userAddressInfo.country != 0) {
            setUserAddressInfo({
                ...userAddressInfo,
                postal_code: '0000000',
            })
        }
    }, [userAddressInfo.country])
    
    const [loading, setLoading] = useState(true)
    const [saveButton, setSaveButton] = useState(t('address_edit.save'))

    const JapanAddress = () => {
        return (
            <> 
                <div className="user-address-form-left">
                    <div className="user-address-form-left-title">{t('address_edit.postal_code')}</div>
                    <input type="text" className="user-address-form-left-input" value={userAddressInfo.postal_code} onChange={(e) => setUserAddressInfo({...userAddressInfo, postal_code: e.target.value})} placeholder={t('address_edit.placeholder')} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-left">
                    <div className="user-address-form-left-title">{t('address_edit.prefecture')}</div>
                    <select className="user-address-form-left-select" value={userAddressInfo.prefecture} onChange={(e) => setUserAddressInfo({...userAddressInfo, prefecture: e.target.value})}>
                        <CreateSelectPrefecture />
                    </select>
                </div> 
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.city')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.city} onChange={(e) => setUserAddressInfo({...userAddressInfo, city: e.target.value})} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.address')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.address2} onChange={(e) => setUserAddressInfo({...userAddressInfo, address2: e.target.value})} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.phone_number')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.phone_number} onChange={(e) => setUserAddressInfo({...userAddressInfo, phone_number: e.target.value})} />
                </div>

                <div className="clearfix"></div>

                <div className="user-address-form-button">
                    <button className="user-address-form-button-back" onClick={moveBackPage}>{t('address_edit.back')}</button>
                    <button className="user-address-form-button-next" onClick={saveHandler}>{saveButton}</button>
                </div>
            </>
        );
    }

    const TaiwanAddress = () => {
        return (
            <>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.address')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.address2} onChange={(e) => setUserAddressInfo({...userAddressInfo, address2: e.target.value})} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.city')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.city} onChange={(e) => setUserAddressInfo({...userAddressInfo, city: e.target.value})} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-left">
                    <div className="user-address-form-left-title">{t('address_edit.postal_code')}</div>
                    <input type="text" className="user-address-form-left-input" value={userAddressInfo.postal_code} onChange={(e) => setUserAddressInfo({...userAddressInfo, postal_code: e.target.value})} placeholder={t('address_edit.placeholder')} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.phone_number')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.phone_number} onChange={(e) => setUserAddressInfo({...userAddressInfo, phone_number: e.target.value})} />
                </div>

                <div className="clearfix"></div>

                <div className="user-address-form-button">
                    <button className="user-address-form-button-back" onClick={moveBackPage}>{t('address_edit.back')}</button>
                    <button className="user-address-form-button-next" onClick={saveHandler}>{saveButton}</button>
                </div>
            </>
        );
    }

    const HongKongAddress = () => {
        return (
            <>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.address')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.address2} onChange={(e) => setUserAddressInfo({...userAddressInfo, address2: e.target.value})} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.city')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.city} onChange={(e) => setUserAddressInfo({...userAddressInfo, city: e.target.value})} />
                </div>
                <div className="user-address-form-left">
                    <div className="user-address-form-left-title">{t('address_edit.postal_code')}</div>
                    <input type="text" className="user-address-form-left-input" value={userAddressInfo.postal_code} onChange={(e) => setUserAddressInfo({...userAddressInfo, postal_code: e.target.value})} placeholder={t('address_edit.placeholder')} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.phone_number')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.phone_number} onChange={(e) => setUserAddressInfo({...userAddressInfo, phone_number: e.target.value})} />
                </div>

                <div className="clearfix"></div>

                <div className="user-address-form-button">
                    <button className="user-address-form-button-back" onClick={moveBackPage}>{t('address_edit.back')}</button>
                    <button className="user-address-form-button-next" onClick={saveHandler}>{saveButton}</button>
                </div>
            </>
        );
    }

    const SingaporeAddress = () => {
        return (
            <>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.address')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.address2} onChange={(e) => setUserAddressInfo({...userAddressInfo, address2: e.target.value})} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.city')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.city} onChange={(e) => setUserAddressInfo({...userAddressInfo, city: e.target.value})} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-left">
                    <div className="user-address-form-left-title">{t('address_edit.postal_code')}</div>
                    <input type="text" className="user-address-form-left-input" value={userAddressInfo.postal_code} onChange={(e) => setUserAddressInfo({...userAddressInfo, postal_code: e.target.value})} placeholder={t('address_edit.placeholder')} />
                </div>
                <div className="clearfix"></div>
                <div className="user-address-form-both">
                    <div className="user-address-form-both-title">{t('address_edit.phone_number')}</div>
                    <input type="text" className="user-address-form-both-input" value={userAddressInfo.phone_number} onChange={(e) => setUserAddressInfo({...userAddressInfo, phone_number: e.target.value})} />
                </div>

                <div className="clearfix"></div>

                <div className="user-address-form-button">
                    <button className="user-address-form-button-back" onClick={moveBackPage}>{t('address_edit.back')}</button>
                    <button className="user-address-form-button-next" onClick={saveHandler}>{saveButton}</button>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="user-address">
                <div className="user-address-title">{t('address_edit.title')}</div>
                {loading ?
                    <>
                        <div className="user-address-form">
                            {t('common.loading')}
                        </div>
                    </>
                :
                    <div className="user-address-form">
                        <div className="user-address-form-left">
                            <div className="user-address-form-right-title">{t('address_edit.first_name')}</div>
                            <input type="text" className="user-address-form-right-input" value={userAddressInfo.first_name} onChange={(e) => setUserAddressInfo({...userAddressInfo, first_name: e.target.value})} />
                        </div>
                        <div className="user-address-form-right">
                        <div className="user-address-form-left-title">{t('address_edit.last_name')}</div>
                            <input type="text" className="user-address-form-left-input" value={userAddressInfo.last_name} onChange={(e) => setUserAddressInfo({...userAddressInfo, last_name: e.target.value})} />
                        </div>
                        {t('address_edit.last_name_kana') && (
                            <>
                                <div className="user-address-form-left">
                                <div className="user-address-form-right-title">{t('address_edit.first_name_kana')}</div>
                                    <input type="text" className="user-address-form-right-input" value={userAddressInfo.first_name_kana} onChange={(e) => setUserAddressInfo({...userAddressInfo, first_name_kana: e.target.value})} />
                                </div>
                                <div className="user-address-form-right">
                                <div className="user-address-form-left-title">{t('address_edit.last_name_kana')}</div>
                                    <input type="text" className="user-address-form-left-input" value={userAddressInfo.last_name_kana} onChange={(e) => setUserAddressInfo({...userAddressInfo, last_name_kana: e.target.value})} />
                                </div>
                            </>
                        )}
                        
                        <div className="user-address-form-left">
                            <div className="user-address-form-left-title">{t('address_edit.country')}</div>
                            <select className="user-address-form-left-select" value={userAddressInfo.country} onChange={(e) => setUserAddressInfo({...userAddressInfo, country: e.target.value})}>
                                <option value="">-</option>
                                <option value="0">{t('address_edit.country_jp')}</option>
                                <option value="1">{t('address_edit.country_tw')}</option>
                                <option value="2">{t('address_edit.country_hk')}</option>
                                <option value="3">{t('address_edit.country_sg')}</option>
                            </select>
                        </div>
                        <div className="clearfix"></div>
                        {userAddressInfo.country == 0 &&
                            JapanAddress()
                        }
                        {userAddressInfo.country == 1 &&
                            TaiwanAddress()
                        }
                        {userAddressInfo.country == 2 &&
                            HongKongAddress()
                        }
                        {userAddressInfo.country == 3 &&
                            SingaporeAddress()
                        }
                    </div>
                }
            </div>
        </>
    )
}

export default AddressEdit