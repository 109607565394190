import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from "react-i18next";

import './Login.css'

const PasswordResetRegister = ({move, shopInfo, token}) => {
    const { t } = useTranslation();
    const [isRevealPassword, setIsRevealPassword] = useState(false);

    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [agree, setAgree] = useState(false);

    const passwordReset = (e) => {

        if (resetButton == t('password_reset_register.submitting')) {
            return;
        }

        setResetButton(t('password_reset_register.submitting'));

        e.preventDefault();
        fetch(process.env.REACT_APP_API_URL + '/sanctum/csrf-cookie', {
            method: 'GET',
            credentials: "include",
        }).then((response) => {
            fetch(process.env.REACT_APP_API_URL + '/api/password/reset/submit', {
                method: 'POST',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify({
                    shop_id: shopInfo.id,
                    token: token,
                    password: password,
                }),
            }).then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    let message = response.json().message;
                    setResetButton(t('password_reset_register.resetregister'));
                    throw new Error(message);
                }
            })
            .then((data) => {
                if (data.message) {
                    alert(data.message);
                }
                setResetButton(t('password_reset_register.resetregister'));
                move('login');
            }).catch((error) => {
                setResetButton(t('password_reset_register.resetregister'));
                alert(t('common.wait'));
            });
        }).catch((error) => {
            setResetButton(t('password_reset_register.resetregister'));
            alert(t('common.wait'));
        });
    }

    const [resetButton, setResetButton] = useState(t('password_reset_register.resetregister'));

    useEffect(() => {

        fetch(process.env.REACT_APP_API_URL + '/api/password/reset/email', {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                shop_id: shopInfo.id,
                token: token,
            }),
        }).then((response) => response.json())
        .then((data) => {
            if (data.email) {
                setEmail(data.email);
            } else {
                alert(t('common.error'));
                move('login');
                return;
            }
            setResetButton(t('password_reset_register.resetregister'));
        }).catch((error) => {
            setResetButton(t('password_reset_register.resetregister'));
            alert(t('common.wait'));
        });
    }, [])

    return (
        <>
            <div className="login">
                <div className="login-title">{t('password_reset_register.title')}</div>
                <div className="login-form">
                    <dl>
                        <dt>{t('password_reset_register.email')}</dt>
                        <dd><input type="text" placeholder={t('password_reset_register.email')} readOnly value={email}/></dd>
                        <dt>{t('password_reset_register.password')}</dt>
                        <dd>
                            <input type={isRevealPassword ? 'text' : 'password'} placeholder={t('password_reset_register.password')} onChange={(e) => setPassword(e.target.value)}/>
                            <span
                            onClick={togglePassword}
                                
                            className="password-toggle-icon"
                            >
                            {isRevealPassword ? (
                                t('password_reset_register.hide')
                            ) : (
                                t('password_reset_register.show')
                            )}
                            </span>
                        </dd>
                    </dl>
                    <div className="login-button">
                        <button onClick={passwordReset}>{resetButton}</button>
                    </div>
                    
                    <div className="login-back">
                    <button onClick={() => {move('login')}}>{t('password_reset_register.back')}</button>
                </div>
                </div>
            </div>
        </>
    )
}

export default PasswordResetRegister