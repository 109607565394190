import React, { useEffect } from 'react'
import { useState } from 'react';
import { useTranslation } from "react-i18next";

import './Login.css'

const PasswordReset = ({move, shopInfo}) => {
    const { t,i18n } = useTranslation();
    const [submited, setSubmited] = React.useState(false);
    const [email, setEmail] = React.useState('');

    const passwordReset = (e) => {
        e.preventDefault();
        if (resetButton == t('password_reset.submitting')) {
            return;
        }

        setResetButton(t('password_reset.submitting'));
        
        fetch(process.env.REACT_APP_API_URL + '/api/password/reset', {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                shop_id: shopInfo.id,
                email: email,
            }),
        }).then((response) => response.json())
        .then((data) => {
            if (data.message) {
                alert(data.message);
                setResetButton(t('password_reset.submit'));
                return;
            } else {
                setSubmited(true);
            }
            setResetButton(t('password_reset.submit'));
        }).catch((error) => {
            setResetButton(t('password_reset.submit'));
            alert(t('common.wait'));
        });
    }

    const [resetButton, setResetButton] = useState(t('password_reset.submit'));
    useEffect(() => {
        // 言語が変更されたときにResetButtonの状態を更新
        const changeLanguage = () => {
            setResetButton(t('password_reset.submit'));
        };

        // 言語が変更されたときにchangeLanguageを実行
        i18n.on('languageChanged', changeLanguage);

        // コンポーネントがアンマウントされたときにイベントリスナーを削除
        return () => {
            i18n.off('languageChanged', changeLanguage);
        };
    }, [t, i18n]);

    return (
        <>
            <div className="login">
                <div className="login-title">{t('password_reset.title')}</div>
                <div className="login-form">
                <div className="login-description">{t('password_reset.description')}</div>
                    <dl>
                        <dt>{t('password_reset.email')}</dt>
                        <dd><input type="text" placeholder={t('password_reset.email')} value={email} onChange={(e) => {setEmail(e.target.value)}} disabled={submited}/></dd>
                    </dl>
                    {submited && <>{t('password_reset.submited')}</>}
                    <div className="login-button">
                        <button onClick={passwordReset}>{resetButton}</button>
                    </div>
                    
                    <div className="login-back-link">
                        <a onClick={() => {move('login')}}>{t('password_reset.link')}</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordReset