import { api } from './api'
import { useTranslation } from "react-i18next";

export const useFincode = (tenantShopId) => {
  const { t } = useTranslation();
  const fincode = window.Fincode(process.env.REACT_APP_FINCODE_PUBLIC_KEY);
  if (tenantShopId && tenantShopId !== "platform") {
    fincode.setTenantShopId(tenantShopId);
  }

  const retryMax = process.env.REACT_APP_FINCODE_RETRY_MAX || 30
  const retryDuration = (process.env.REACT_APP_FINCODE_RETRY_DURATION || 1) * 1000

  const ui = (id, type, width, appearance = {}) => {

    const app = {
      layout: "vertical",
      hideLabel: false, hideHolderName: false, hidePayTimes: true,
      labelCardNo: "カード番号", labelExpire: "有効期限", labelCvc: "セキュリティコード", labelHolderName: "カード名義人",
      cardNo: "1234 5678 9012 3456", expireMonth: "01", expireYear: "25", cvc: "001", holderName: "TARO YAMADA",
      ...appearance
    }

    const ui = fincode.ui(app)
    ui.create(type, app)
    ui.mount(id, width)

    return ui
  }

  const cards = (card) => new Promise((resolve, reject) => {
    fincode.cards(
      card,
      (status, response) => (status === 200) ? resolve(response) : reject(response),
      e => resolve(e)
    )
  })

  const payments = (transaction) => new Promise((resolve, reject) => {
    fincode.payments(
      transaction,
      (status, response) => (status === 200) ? resolve(response) : reject(response),
      e => resolve(e)
    )
  })

  const listCard = (customerId) => new Promise((resolve, reject) => {
    fincode.getCardsList(
      customerId,
      (status, response) => (status === 200) ? resolve(response.list) : reject(response),
      e => resolve(e)
    )
  })

  const checkStatus = async (accessId, checkFn, retry = 0) => {
    try {
      const result = await api.get(`/payments/secure/${accessId}`)
      if (checkFn(result.tds2_trans_result))
        return result

      throw new Error('no result')
    } catch (e) {
      if (retry > retryMax)
        throw new Error(t('settlement.timeout'))

      await new Promise(resolve => setTimeout(resolve, retryDuration));
      return await checkStatus(accessId, checkFn, retry + 1)
    }
  }

  const acsCheck = async (iframeId, acsUrl, accessId) => {
    const iframe = document.createElement('iframe')
    iframe.src = acsUrl

    document.getElementById(iframeId).appendChild(iframe)
    const result = await checkStatus(accessId, (tds2_trans_result) => !!tds2_trans_result)
    document.getElementById(iframeId).removeChild(iframe)

    return result
  }

  const tdsCheck = async (iframeId, challengeUrl, accessId, loadingStartFn, loadingEndFn) => {
    const iframe = document.createElement('iframe')
    iframe.src = challengeUrl

    loadingStartFn()
    document.getElementById(iframeId).appendChild(iframe)
    const result = await checkStatus(accessId, (tds2_trans_result) => tds2_trans_result !== "C")
    document.getElementById(iframeId).removeChild(iframe)
    loadingEndFn()

    return result
  }

  const tdsTransCheck = (result) => {
    switch (result) {
      case "Y": case "C": return
      case "N": throw new Error(t('settlement.error'))
      default: throw new Error(t('settlement.error'))
    }
  }

  return {
    ui,
    cards,
    payments,
    acsCheck,
    tdsCheck,
    tdsTransCheck,
    listCard,
  }
}
