import React from 'react'
import { useState } from 'react'
import { useTranslation } from "react-i18next"
import { useEffect } from 'react'

import './Login.css'

const Register = ({move, shopInfo, setIsLogined, setSessionToken, shopPath}) => {
    const { t } = useTranslation();
    const [isRevealPassword, setIsRevealPassword] = useState(false);

    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }

    const passwordReset = (e) => {
        e.preventDefault();
        move('index')
    }
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [agree, setAgree] = useState(false);

    const register = (e) => {

        if (!agree) {
            alert(t('register.error'));
            return;
        }

        if (registerButton === t('register.registering')) {
            return;
        }

        e.preventDefault();
        setRegisterButton(t('register.registering'));
        fetch(process.env.REACT_APP_API_URL + '/api/register', {
            method: 'POST',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                shop_id: shopInfo.id,
                email: email,
                password: password,
            }),
        }).then((response) => response.json())
        .then((data) => {
            if (data.token) {
                setIsLogined(true);
                setSessionToken(data.token);
                document.cookie = 'token=' + data.token + ';max-age=946080000;path=/';
                move('index');
            } else {
                if (data.message) {
                    alert(data.message);
                } else {
                    alert(Object.values(data)[0]);
                }
                setRegisterButton(t('register.register'));
                return;
            }
        }).catch((error) => {
            setRegisterButton(t('register.register'));
            alert(error);
        });
    }


    const [registerButton, setRegisterButton] = useState(t('register.register'));

    return (
        <>
            <div className="login">
                <div className="login-title">{t('register.title')}</div>
                <div className="login-form">
                    <dl>
                        <dt>{t('register.email')}</dt>
                        <dd><input type="text" placeholder={t('register.email')} onChange={(e) => setEmail(e.target.value)}/></dd>
                        <dt>{t('register.password')}</dt>
                        <dd>
                            <input type={isRevealPassword ? 'text' : 'password'} placeholder={t('register.password')} onChange={(e) => setPassword(e.target.value)}/>
                            <span
                            onClick={togglePassword}
                                
                            className="password-toggle-icon"
                            >
                            {isRevealPassword ? (
                                t('register.hide')
                            ) : (
                                t('register.show')
                            )}
                            </span>
                        </dd>
                    </dl>
                    <div className="login-policy">
                        <label className="CheckboxInput">
                        <input className="CheckboxInput-Input" type="checkbox" onChange={(e) => setAgree(e.target.checked)}/>
                        <span className="CheckboxInput-DummyInput"></span>
                        <span className="CheckboxInput-LabelText">
                        <a href={`${shopPath}/terms-of-service`} target="_blank">{t('common.service')}</a>{t('register.text1')}
                        <a target="_blank" href={`${shopPath}/privacy-policy`}>{t('common.privacy')}</a>{t('register.text2')}
                        </span></label>
                    </div>
                    <div className="login-button">
                        <button onClick={register}>{registerButton}</button>
                    </div>
                    
                    <div className="login-back">
                    <button onClick={() => {move('login')}}>{t('register.back')}</button>
                </div>
                </div>
            </div>
        </>
    )
}

export default Register