import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'


import './Gacha.css'

import GachaMovie from './GachaMovie'
import PointNotEnough from './Modal/PointNotEnough'
import GachaStart from './Modal/GachaStart'
import GachaStartAll from './Modal/GachaStartAll'

import GachaRank1Info from './GachaRank1Info'
import GachaRank2Info from './GachaRank2Info'
import GachaRank3Info from './GachaRank3Info'
import GachaRank9Info from './GachaRank9Info'
import { useTranslation } from "react-i18next";

const Gacha = ({move, id, shopInfo, userInfo, setUserInfo, shopPath, packageId, gachaType}) => {
    const { t } = useTranslation();

    if (packageId) {
        id = packageId;
    }

    const [loadingText, setLoadingText] = useState(t('common.loading'));

    const [gachaHash, setGachaHash] = useState("");

    const [gachaStarted, setGachaStarted] = useState(false);
    const [packageMasterList, setPackageMasterList] = React.useState([]);
    const [packageInfo, setPackageInfo] = useState({
        id: 0,
        type: 0,
        id: 0,
        price: 0,
        stock: 0,
        number: 0,
        hidden_from: '',
        img_path: '',
        name: '',
        description: '',
        is_psa_enabled: false,
        psa: 0,
        max_number_per_one_draw: 0,
        depends_on_hold_points: false,

    })
    const [cardList, setCardList] = useState([]);
    
    const [gachMovie, changeGachaMovie] = useState(false);
    const [modalIsOpen, changeModalIsOpen] = useState(false);

    const [gachaModal, changeGachaModal] = useState(false);
    const [gachaAllModal, changeGachaAllModal] = useState(false);

    const [drawableCount, setDrawableCount] = useState(0);
    const [hitRank, setHitRank] = useState(4);
    const [hitId, setHitId] = useState(0);

    const showGachaMovieHandler = () => {
        changeGachaMovie(true);
    }

    const skipGachaHandler = () => {
        changeGachaMovie(false);
        // move('gacha-won', hitId);
        // window.history.replaceState(null, null, `/${shopPath}/h/${hitId}`);
        move('gacha-won', hitId);
    }

    const [times, setTimes] = useState(1);
    const [point, setPoint] = useState(0);

    const [allPoint, setAllPoint] = useState(0);

    const gachaEvent = (type) => {
        // e.preventDefault();
        if (userInfo && packageInfo.price > userInfo.point) {
            changeGachaMovie(false);
            changeModalIsOpen(true);
        } 
        else if (drawableCount > 0 && packageInfo && packageInfo.price != "") {
            if (type == 'multi') {
                if (!userInfo || packageInfo.price * drawableCount > userInfo.point) {
                    changeGachaMovie(false);
                    changeModalIsOpen(true);
                    return;
                }
                
                setTimes(drawableCount);
                setPoint(packageInfo.price * drawableCount);

                window.dataLayer.push({
                    'event': 'gacha-multiple',
                    'point': packageInfo.price * drawableCount,
                    'times': drawableCount,
                    'package_id': packageInfo.id,
                    'package_name': packageInfo.name,
                    'package_price': packageInfo.price,
                });
            } else {
                setTimes(1);
                setPoint(packageInfo.price);

                window.dataLayer.push({
                    'event': 'gacha-single',
                    'point': packageInfo.price,
                    'times': 1,
                    'package_id': packageInfo.id,
                    'package_name': packageInfo.name,
                    'package_price': packageInfo.price,
                });
            }
            changeGachaModal(true);
        }
    }

    const gachaHandler = (e) => {
        e.preventDefault();

        if (userInfo && packageInfo.price > userInfo.point) {
            changeGachaMovie(false);
            changeModalIsOpen(true);
        } 
        else if (drawableCount > 0) {
            if (e.target.className === 'gacha-button-10') {
                if (packageInfo.price * drawableCount > userInfo.point) {
                    changeGachaMovie(false);
                    changeModalIsOpen(true);
                    return;
                }
                
                setTimes(drawableCount);
                setPoint(packageInfo.price * drawableCount);

                window.dataLayer.push({
                    'event': 'gacha-multiple',
                    'point': packageInfo.price * drawableCount,
                    'times': drawableCount,
                    'package_id': packageInfo.id,
                    'package_name': packageInfo.name,
                    'package_price': packageInfo.price,
                });
            } else {
                setTimes(1);
                setPoint(packageInfo.price);

                window.dataLayer.push({
                    'event': 'gacha-single',
                    'point': packageInfo.price,
                    'times': 1,
                    'package_id': packageInfo.id,
                    'package_name': packageInfo.name,
                    'package_price': packageInfo.price,
                });
            }
            changeGachaModal(true);
        }
    }

    const gachaHandlerAll = (e) => {
        e.preventDefault();

        if (userInfo && packageInfo.price > userInfo.point) {
            changeGachaMovie(false);
            changeModalIsOpen(true);
        }
        else {
            if (packageInfo.hidden_from && packageInfo.stock != 0 && packageInfo.stock <= packageInfo.hidden_from) {
                let maxDrawableCount = packageInfo.hidden_from;
                if (maxDrawableCount > packageInfo.left_limited_number_per_user) {
                    maxDrawableCount = packageInfo.left_limited_number_per_user;
                }
                if (maxDrawableCount > packageInfo.left_limited_number_per_user_per_day) {
                    maxDrawableCount = packageInfo.left_limited_number_per_user_per_day;
                }
                setTimes(drawableCount);
                setAllPoint(maxDrawableCount * packageInfo.price);
                console.log(allPoint);
                changeGachaAllModal(true);

                window.dataLayer.push({
                    'event': 'gacha-all',
                    'point': maxDrawableCount * packageInfo.price,
                    'times': maxDrawableCount,
                    'package_id': packageInfo.id,
                    'package_name': packageInfo.name,
                    'package_price': packageInfo.price,
                });
            } else {
                setTimes(drawableCount);
                setAllPoint(packageInfo.stock * packageInfo.price);
                console.log(allPoint);
                changeGachaAllModal(true);

                window.dataLayer.push({
                    'event': 'gacha-all',
                    'point': packageInfo.stock * packageInfo.price,
                    'times': packageInfo.stock,
                    'package_id': packageInfo.id,
                    'package_name': packageInfo.name,
                    'package_price': packageInfo.price,
                });
            }
        }
    }

    const gacha = (e) => {
        e.preventDefault();
        if (!userInfo) {    
            move('login');
            return;
        }

        if (gachaStarted) {
            return;
        }
        setGachaStarted(true);

        if (userInfo && packageInfo.price > userInfo.point) {
            changeGachaMovie(false);
            changeModalIsOpen(true);
            setGachaStarted(false);
        } else {
            if (drawableCount > 0) {
                // let times = 1;
                // if (e.target.className === 'gacha-button-10') {
                //     times = drawableCount;
                // }

                // window.history.replaceState(null, null, `/${shopPath}`);

                // window.addEventListener('beforeunload', function (e) {
                //     if (shopPath) {
                //         window.history.replaceState(null, null, `/${shopPath}`);
                //     } else {
                //         window.history.replaceState(null, null, `/`);
                //     }
                //     e.preventDefault();
                //     e.returnValue = '';
                // });

                setGachaButton(t('gacha.loading'));

                let token = document.cookie.split('; ').find(row => row.startsWith('token'));
                if (token) {
                    token = token.split('=')[1]
                }
                fetch(`${process.env.REACT_APP_API_URL}/api/package/draw`, {
                    method: 'POST',
                    cache: 'no-store',
                    headers: {
                        'Authorization': `Bearer ${decodeURIComponent(token)}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        package_id: packageInfo.id,
                        times: times,
                        hash: gachaHash,
                    })
                })
                .then(response => response.json())
                .then(data => {
                    if(!data.errors) {
                        setHitRank(data.hits.rank);
                        setHitId(data.hits.hit_id);
                        setUserInfo({
                            ...userInfo,
                            id: data.user.id,
                            point: data.user.balance,
                            expired_point: data.user.expired_balance.balance,
                            expired_date: data.user.expired_balance.expired_date,
                        });

                        showGachaMovieHandler();

                        // window.history.replaceState(null, null, `/${shopPath}/h/${data.hits.hit_id}`);
                        window.addEventListener('beforeunload', function (e) {
                            if (shopPath) {
                                window.history.replaceState(null, null, `/${shopPath}/h/${data.hits.hit_id}`);
                            } else {
                                window.history.replaceState(null, null, `/h/${data.hits.hit_id}`);
                            }
                        });
                    } else {
                        alert(t('gacha.error'));
                    }
                    setGachaButton(t('gacha_start.fixed'));
                    setGachaStarted(false);
                })
                .catch(error => {
                    setGachaButton(t('gacha_start.fixed'));
                    alert(t('gacha.wait'));
                    setGachaStarted(false);
                });
            } else {
                // changeModalIsOpen(true);
                setGachaStarted(false);
            }
        }
    }

    const gachaAll = (e) => {
        e.preventDefault();
        if (!userInfo) {    
            move('login');
            return;
        }

        if (gachaStarted) {
            return;
        }
        setGachaStarted(true);

        if (userInfo && packageInfo.price > userInfo.point) {
            changeGachaMovie(false);
            changeModalIsOpen(true);
            setGachaStarted(false);
        } else {
            if (drawableCount > 0) {
                // let times = 1;
                // if (e.target.className === 'gacha-button-10') {
                //     times = drawableCount;
                // }

                // window.history.replaceState(null, null, `/${shopPath}`);

                // window.addEventListener('beforeunload', function (e) {
                //     if (shopPath) {
                //         window.history.replaceState(null, null, `/${shopPath}`);
                //     } else {
                //         window.history.replaceState(null, null, `/`);
                //     }
                // });

                setGachaButton(t('gacha.loading'));

                let token = document.cookie.split('; ').find(row => row.startsWith('token'));
                if (token) {
                    token = token.split('=')[1]
                }
                fetch(`${process.env.REACT_APP_API_URL}/api/package/draw/all`, {
                    method: 'POST',
                    cache: 'no-store',
                    headers: {
                        'Authorization': `Bearer ${decodeURIComponent(token)}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        package_id: packageInfo.id,
                        hash: gachaHash,
                    })
                })
                .then(response => response.json())
                .then(data => {
                    if(!data.errors) {
                        setHitRank(data.hits.rank);
                        setHitId(data.hits.hit_id);
                        setUserInfo({
                            ...userInfo,
                            id: data.user.id,
                            point: data.user.balance,
                            expired_point: data.user.expired_balance.balance,
                            expired_date: data.user.expired_balance.expired_date,
                        });

                        showGachaMovieHandler();

                        // window.history.replaceState(null, null, `/${shopPath}/h/${data.hits.hit_id}`);
                        // window.addEventListener('beforeunload', function (e) {
                        //     if (shopPath) {
                        //         window.history.replaceState(null, null, `/${shopPath}/h/${data.hits.hit_id}`);
                        //     } else {
                        //         window.history.replaceState(null, null, `/h/${data.hits.hit_id}`);
                        //     }
                        // });
                    } else {
                        alert(t('gacha.error'));
                    }
                    setGachaButton(t('gacha_start.fixed'));
                    setGachaStarted(false);
                })
                .catch(error => {
                    setGachaButton(t('gacha_start.fixed'));
                    alert(t('gacha.wait'));
                    setGachaStarted(false);
                });
            } else {
                // changeModalIsOpen(true);
                setGachaStarted(false);
            }
        }
    }

    useEffect(() => {
        if (shopInfo.id) {
            fetch(`${process.env.REACT_APP_API_URL}/api/package-master?shop_id=${shopInfo.id}`, {
                method: 'GET',
                headers: {
                },
                credentials: 'include',
            })
            .then(response => response.json())
            .then(data => {
                if(!data.errors) {
                    setPackageMasterList(data.package_type_masters);
                } else {
                    alert(Object.values(data.errors)[0]);
                }
            })
            .catch(error => {
                console.error(error);
            });
            
            let api = `${process.env.REACT_APP_API_URL}/api/package/${id}?shop_id=${shopInfo.id}`;
            let token = document.cookie.split('; ').find(row => row.startsWith('token'));
            if (token) {
                token = token.split('=')[1]
                api = `${process.env.REACT_APP_API_URL}/api/package/${id}/user?shop_id=${shopInfo.id}`;
            }

            // packagecard list
            fetch(api, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${decodeURIComponent(token)}`,
                    'Accept': 'application/json',
                },
                credentials: 'include',
            })
            .then(response => response.json())
            .then(data => {
                if(!data.errors) {
                    if(data.package === null) {
                        move('index');
                        return;
                    }

                    setPackageInfo(data.package);
                    setCardList(data.package.package_cards);
                    setGachaHash(data.package.hash);
                    document.cookie = `package_type=${data.package.type}; max-age=2592000; path=/`;

                    if (shopPath) {
                        window.history.replaceState(null, null, `/${shopPath}/${id}`);
                    } else {
                        window.history.replaceState(null, null, `/${id}`);
                    }
                } else {
                    alert(Object.values(data.errors)[0]);
                }
                setLoadingText('');
             })
        }
    }, [shopInfo]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (packageInfo && packageInfo.price >= 0) {
            if (gachaType == 'single') {
                gachaEvent('single');
            }

            if (gachaType == 'multi') {
                gachaEvent('multi');
            }
        }
    }, [packageInfo, drawableCount])

    useEffect(() => {
        // 残りガチャが何回引けるかどうか計算
        let drawable = 0;
        if (packageInfo.depends_on_hold_points) {   
            if (packageInfo.price > 0 && userInfo) {
                drawable = Math.floor(userInfo.point / packageInfo.price);
            }
        } else {
            drawable = packageInfo.max_number_per_one_draw;
        }
        if (packageInfo.stock > 0 && packageInfo.stock < drawable) {
            drawable = packageInfo.stock;
        }
        if (drawable > packageInfo.max_number_per_one_draw) {
            drawable = packageInfo.max_number_per_one_draw;
        }
        if (drawable > packageInfo.left_limited_number_per_user) {
            drawable = packageInfo.left_limited_number_per_user;
        }
        if (drawable > packageInfo.left_limited_number_per_user_per_day) {
            drawable = packageInfo.left_limited_number_per_user_per_day;
        }
        if (drawable < 1) {
            drawable = 1;
        }
        if (packageInfo.stock == -1) {
            drawable = packageInfo.max_number_per_one_draw;
        }
        if (packageInfo.stock == -2) {
            drawable = 1;
        }

        setDrawableCount(drawable);
    }, [packageInfo, userInfo]);

    useEffect(() => {
        if (hitId) {
            if (shopPath) {
                window.history.replaceState(null, null, `/${shopPath}/h/${hitId}`);
            } else {
                window.history.replaceState(null, null, `/h/${hitId}`);
            }

            // window.removeEventListener('beforeunload', function (e) {
            //     e.preventDefault();
            //     e.returnValue = '';
            // });
        }
    }, [hitId]);

    const [packageClass, setPackageClass] = useState('gacha-package');
    const [isSoldOut, setIsSoldOut] = useState(false);


    useEffect(() => {
        if (packageInfo.status === 2 || packageInfo.status === 6) {
            setPackageClass('gacha-package')
            setIsSoldOut(false);
        } else if (packageInfo.status === 3) {
            setPackageClass('gacha-package package-sold-out')
            setIsSoldOut(t('package.soldout'));
        } else if (packageInfo.status === 4) {
            setPackageClass('gacha-package package-end')
            setIsSoldOut(t('package.end'));
            
        }
    }, [packageInfo.status]);

    const [gachaButton, setGachaButton] = useState(t('gacha_start.fixed'));

    const [displayFourthRankButton, setDisplayFourthRankButton] = useState(t('gacha.detail'));

    const [displayFourthRank, setDisplayFourthRank] = useState(false);

    useEffect(() => {
        let description = '';
        let name = packageInfo.name;
        description += name + t('gacha.sale');
        
        let cardList = packageInfo.package_cards;
        if (cardList && cardList.length > 0) {
            if (cardList.filter(card => card.rank === 1).length > 0) {
                let point = packageInfo.price;
                description += t('gacha.description1') + point + t('gacha.description2');

                let firstRankCardNameList = cardList.filter(card => card.rank === 1).map(card => card.name).slice(0, 3);
                if (firstRankCardNameList.length > 1) {
                    description += t('gacha.description3') +  firstRankCardNameList.join(t('gacha.description4')) + t('gacha.description5');
                } else {
                    if (firstRankCardNameList.length > 0) {
                        description += t('gacha.description3') + firstRankCardNameList[0] + t('gacha.description6');
                    }
                }
            }

            if (cardList.filter(card => card.rank === 9).length > 0) {
                let lastRankCardNameList = cardList.filter(card => card.rank === 9).map(card => card.name);
                if (lastRankCardNameList.length > 0) {
                    description += t('gacha.description7') +  t('gacha.description3') + lastRankCardNameList.join(t('gacha.description4')) + t('gacha.description8');
                }
            }
        }

        let shopName = shopInfo.name;

        // titleを name | shopNameにする
        document.title = name + ' | ' + shopName;

        let metaDiscre = document.head.children;
        let metaLength = metaDiscre.length;
        for(var i = 0;i < metaLength;i++){
            let proper = metaDiscre[i].getAttribute('name');
            if(proper === 'description'){
                let content = metaDiscre[i].getAttribute('content');
                metaDiscre[i].setAttribute('content', t('gacha.content1') + shopName +  t('gacha.content2') + description);
            }
        }

        // twitter card
        let metaTwitter = document.head.children;
        let metaTwitterLength = metaTwitter.length;
        for(var i = 0;i < metaTwitterLength;i++){
            let proper = metaTwitter[i].getAttribute('name');
            if(proper === 'twitter:description'){
                let content = metaTwitter[i].getAttribute('content');
                metaTwitter[i].setAttribute('content', t('gacha.content1') + shopName +  t('gacha.content2') + description);
            }
            if(proper === 'twitter:title'){
                let content = metaTwitter[i].getAttribute('content');
                metaTwitter[i].setAttribute('content', name + ' | ' + shopName);
            }
            if(proper === 'twitter:image'){
                let content = metaTwitter[i].getAttribute('content');
                metaTwitter[i].setAttribute('content', process.env.REACT_APP_ASSET_URL + packageInfo.image_url);
            }
        }

        // ogp
        let metaOgp = document.head.children;
        let metaOgpLength = metaOgp.length;
        for(var i = 0;i < metaOgpLength;i++){
            let proper = metaOgp[i].getAttribute('property');
            if(proper === 'og:description'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', t('gacha.content1') + shopName +  t('gacha.content2') + description);
            }
            if(proper === 'og:title'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', name + ' | ' + shopName);
            }
            if(proper === 'og:image'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', process.env.REACT_APP_ASSET_URL + packageInfo.image_url);
            }
            if(proper === 'og:url'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', window.location.href);
            }
            if(proper === 'og:site_name'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', shopName);
            }
        }


    }, [packageInfo]);
    
    return (
        <>
            <div className="gacha">
                <div className="gacha-back">
                    <button  onClick={() => move('index')}>
                        {t('gacha.back')}
                    </button>
                </div>
                {loadingText ? 
                    <>
                        {loadingText}
                    </>
                :
                    <>
                        <div className={packageClass}>
                            {isSoldOut && 
                                <div className="after-content" style={{
                                    position: 'absolute',
                                    top: '35%',
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: '3rem',
                                    fontWeight: 600,
                                    color: '#000',
                                    zIndex: 999,
                                }}>
                                    {isSoldOut}
                                </div>
                            }
                            <div className="gacha-package-image">
                                <img src={process.env.REACT_APP_ASSET_URL + packageInfo.image_url} alt="package" />
                                <div className="require-pt">
                                    <div className="require-pt-icon">
                                        <img src="/img/balance.svg" alt="pt" />
                                    </div>
                                    <div className="require-pt-value">{packageInfo.price.toLocaleString()} pt</div>
                                </div>
                            </div>
                        </div>
                        <div className="stock">
                        {!packageInfo.hidden_from || packageInfo.stock == 0 || packageInfo.stock > packageInfo.hidden_from ? (
                            <>
                                <div className="stock-seek-bar">
                                    <div 
                                        className={
                                            packageInfo.stock / packageInfo.number * 100 < 25 ? 'stock-seek-bar-inner stock-seek-bar-inner-25-percent' :
                                            packageInfo.stock / packageInfo.number * 100 < 50 ? 'stock-seek-bar-inner stock-seek-bar-inner-50-percent' :
                                            packageInfo.stock / packageInfo.number * 100 < 75 ? 'stock-seek-bar-inner stock-seek-bar-inner-75-percent' :
                                            'stock-seek-bar-inner stock-seek-bar-inner-75-percent'
                                        }
                                        style={{ width: (packageInfo.stock / packageInfo.number * 100 > 99 ? 99 : packageInfo.stock / packageInfo.number * 100)+'%' }}>
                                    </div>
                                </div>
                                <div className="stock-value">
                                    {t('gacha.stock')} <span className="stock-now-text">{packageInfo.stock.toLocaleString()}</span>/<span className="stock-total-text">{packageInfo.number.toLocaleString()}</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="stock-seek-bar">
                                    <div className="stock-seek-bar-inner stock-seek-bar-inner-rainbow"></div>
                                </div>
                                <div className="stock-value">
                                    {t('gacha.stock')} <span className="stock-now-text">???</span>/<span className="stock-total-text">{packageInfo.number.toLocaleString()}</span>
                                </div>
                            </>
                        )}
                        </div>
                        <div className="gacha-info" {...(packageInfo.pop_image_url && {
                            style: {
                                padding: '0px',
                                backgroundColor: 'none',
                                backgroundImage: 'none',
                            }
                        })}>
                        {packageInfo.pop_image_url ? (
                            <>
                            <div className="gacha-info-detail-image">
                                <img src={process.env.REACT_APP_ASSET_URL + packageInfo.pop_image_url} alt="pop" />
                            </div>
                            {cardList && cardList.filter(card => card.rank === 4).length > 0 && (
                                <>
                                <div className="gacha-info-detail">
                                    その他あり
                                    <div className="gacha-info-card-images">
                                        {packageInfo.display_fourth_rank == 1 &&
                                            <>
                                            {displayFourthRank ?
                                                <>
                                                <div className="gacha-info-open-button" onClick={() => {
                                                    setDisplayFourthRank(false);
                                                    setDisplayFourthRankButton('▶︎ 詳細はこちら');
                                                }}>
                                                    {displayFourthRankButton}
                                                </div>
                                                <GachaRank3Info
                                                    cards={
                                                        cardList.filter(card => card.rank === 4)
                                                    }
                                                />
                                                </>
                                            :
                                                <div className="gacha-info-open-button" onClick={() => {
                                                    setDisplayFourthRank(true);
                                                    setDisplayFourthRankButton('▼ 詳細を閉じる');
                                                }}>
                                                    {displayFourthRankButton}
                                                </div>
                                            }
                                            </>
                                        }
                                    </div>
                                </div>
                                </>
                            )}
                            </>
                        ) : (
                        <>
                            {cardList.filter(card => card.rank === 1).length > 0 && (
                                <div className="gacha-info-detail">
                                    <div className="gacha-info-rank-image">
                                        <img src="/img/1stprize.png" alt="rank" />
                                    </div>
                                    <div className="gacha-info-card-images">
                                        <GachaRank1Info
                                            cards={
                                                cardList.filter(card => card.rank === 1)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {cardList.filter(card => card.rank === 2).length > 0 && (
                                <div className="gacha-info-detail">
                                    <div className="gacha-info-rank-image">
                                        <img src="/img/2ndprize.png" alt="rank" />
                                    </div>
                                    <div className="gacha-info-card-images">
                                        <GachaRank2Info
                                            cards={
                                                cardList.filter(card => card.rank === 2)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {cardList.filter(card => card.rank === 3).length > 0 && (
                                <div className="gacha-info-detail">
                                    <div className="gacha-info-rank-image">
                                        <img src="/img/3rdprize.png" alt="rank" />
                                    </div>
                                    <div className="gacha-info-card-images">
                                        <GachaRank3Info
                                            cards={
                                                cardList.filter(card => card.rank === 3)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            {cardList.filter(card => card.rank === 4).length > 0 && (
                                <>
                                <div className="gacha-info-detail">
                                    {t('gacha.other')}
                                    <div className="gacha-info-card-images">
                                        {packageInfo.display_fourth_rank == 1 &&
                                            <>
                                            {displayFourthRank ?
                                                <>
                                                <div className="gacha-info-open-button" onClick={() => {
                                                    setDisplayFourthRank(false);
                                                    setDisplayFourthRankButton(t('gacha.detail'));
                                                }}>
                                                    {displayFourthRankButton}
                                                </div>
                                                <GachaRank3Info
                                                    cards={
                                                        cardList.filter(card => card.rank === 4)
                                                    }
                                                />
                                                </>
                                            :
                                                <div className="gacha-info-open-button" onClick={() => {
                                                    setDisplayFourthRank(true);
                                                    setDisplayFourthRankButton(t('gacha.close'));
                                                }}>
                                                    {displayFourthRankButton}
                                                </div>
                                            }
                                            </>
                                        }
                                    </div>
                                </div>
                                {cardList.filter(card => card.rank === 9).length > 0 && (
                                    <div className="gacha-hr"></div>
                                )}
                                </>
                            )}
                            {cardList.filter(card => card.rank === 9).length > 0 && (
                                <div className="gacha-info-detail">
                                    <div className="gacha-info-rank-image">
                                        <img src="/img/rank-last.png" alt="rank" />
                                    </div>
                                    <div className="gacha-info-card-images">
                                        <GachaRank9Info
                                            cards={
                                                cardList.filter(card => card.rank === 9).reverse()
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                        )}
                        </div>
                        {!packageInfo.pop_image_url &&
                            <div className="gacha-info-note">
                                <div className='gacha-info-note-title'>{shopInfo.noteTitle}</div>
                                <div className='gacha-info-note-text'  style={{whiteSpace: 'pre-line'}}>
                                    {shopInfo.noteContent}
                                </div>
                            </div>
                        }
                        <div className="gacha-button">
                            {packageInfo.status !== 2 || packageInfo.over_limited_number_per_user || packageInfo.over_limited_number_per_user_per_day ?
                            <>
                                <button className="gacha-button-1 gacha-button-disabled">
                                    <img src={document.getElementById("favicon").href}/>
                                    {t('gacha.gacha1')}1{t('gacha.gacha2')}
                                </button>
                                <button className="gacha-button-10 gacha-button-disabled">
                                    <img src={document.getElementById("favicon").href}/>
                                    {t('gacha.gacha1')}{drawableCount}{t('gacha.gacha2')}
                                </button>
                            </>
                            :
                            <>
                                <button className="gacha-button-1" onClick={gachaHandler}>
                                    <img src={document.getElementById("favicon").href}/>
                                    {t('gacha.gacha1')}1{t('gacha.gacha2')}
                                </button>
                                <button className="gacha-button-10" onClick={gachaHandler}>
                                    <img src={document.getElementById("favicon").href}/>
                                    {t('gacha.gacha1')}{drawableCount}{t('gacha.gacha2')}
                                </button>
                                {packageInfo.all_draw &&
                                    <button className="gacha-button-all" onClick={gachaHandlerAll}>
                                        <img src={document.getElementById("favicon").href}/>
                                        {t('gacha.all')}
                                    </button>
                                }
                            </>
                            }
                        </div>
                    </>
                }
            </div>
            {gachMovie ?
                <GachaMovie skipGacha={skipGachaHandler} hitRank={hitRank} shopInfo={shopInfo} packageInfo={packageInfo} /> :
                <PointNotEnough modalIsOpen={modalIsOpen} changeModalIsOpen={changeModalIsOpen} move={move} />
            }
            {changeModalIsOpen && <GachaStart 
                modalIsOpen={gachaModal}
                changeModalIsOpen={changeGachaModal}
                times={times}
                point={point}
                gacha={gacha}
                gachaButton={gachaButton}
            />}
            {changeModalIsOpen && <GachaStartAll
                modalIsOpen={gachaAllModal}
                changeModalIsOpen={changeGachaAllModal}
                point={allPoint}
                gacha={gachaAll}
                gachaButton={gachaButton}
            />}
        </>
    )
}

export default Gacha
