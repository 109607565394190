import React from 'react'
import { useState } from 'react'

import './Gacha.css'
import { useTranslation } from "react-i18next";

const GachaWonCard = ({card, handleSelect, isSelected, shopInfo, menu}) => {
    const { t } = useTranslation();
    
    if (isSelected == undefined) {
        return (
            <>
                <div className="gacha-won-card gacha-won-card-no-check">
                    <div className="gacha-won-card-image">
                        <img src={process.env.REACT_APP_ASSET_URL + card.image_url} alt="card" />
                    </div>
                    <div className="gacha-won-card-balance-image">
                        <img src="img/balance.svg" alt="card" />
                    </div>
                    <div className="gacha-won-card-name">{card.is_psa_enabled == 1 && "(PSA" + card.psa + ")"} {card.name}</div>
                    <div className="gacha-won-card-rarity">
                        {card.rank == 9 ?
                            t('gacha_won_card.last')
                        :
                        card.rank + t('gacha_won_card.rarity')}</div>
                    <div className="gacha-won-card-point"></div>
                    {menu == 1 && card.shipping_date &&
                        <div className="gacha-won-card-period">{t('gacha_won_card.scheduled')}{card.shipping_date} {t('gacha_won_card.to')}</div>
                    }
                    {menu == 2 && card.shipped_date &&
                        <div className="gacha-won-card-period">{t('gacha_won_card.shipped_date')}{card.shipped_date}</div>
                    }
                    <div className="gacha-won-card-checkbox"></div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className={isSelected(card.hit_package_card_id) ? "gacha-won-card gacha-won-card-checked" : "gacha-won-card"} onClick={() => handleSelect(card.hit_package_card_id)}>
                    <div className="gacha-won-card-image">
                        <img src={process.env.REACT_APP_ASSET_URL + card.image_url} alt="card" />
                    </div>
                    <div className="gacha-won-card-balance-image">
                        <img src="/img/balance.svg" alt="card" />
                    </div>
                    <div className="gacha-won-card-name">{card.name} {card.is_psa_enabled == 1 && "(PSA" + card.psa + ")"}</div>
                    <div className="gacha-won-card-rarity">
                        {card.rank == 9 ?
                            t('gacha_won_card.last')
                        :
                        card.rank + t('gacha_won_card.rarity')}</div>
                    <div className="gacha-won-card-point">{card.point}pt</div>
                    {card.hold_date &&
                        <div className="gacha-won-card-period">{t('gacha_won_card.hold_date')}{card.hold_date}</div>
                    }
                    <div className="gacha-won-card-checkbox"></div>
                </div>
            </>
        )
    }
}

export default GachaWonCard