import Axios from 'axios';

export const api = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  timeout: 10000,
});

api.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers.Accept = 'application/json';
  const token = document.cookie.split('; ').find(row => row.startsWith('token'))?.split('=')[1];
  if (token)
    config.headers.Authorization = `Bearer ${decodeURIComponent(token)}`;
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    // console.log('error', 'Error', message); // or use notification

    return Promise.reject(error);
  }
);

