import { useEffect, useState } from 'react'
import Modal from 'react-modal';

import './Settlement.css';

import { api } from '../../../../lib/api'
import { useTranslation } from "react-i18next";

// 決済実行するモーダルのコンポーネント
const Settlement = ({ confirmOpened, settlement, fincode, handlePaymentResult }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true)

  const execute = async () => {
    const { point, customer_id, card_id, card_no, expire, security_code } = settlement


    const { id: order_id, access_id, error } =
      await api.post('/point/payments', { point }).catch(error => ({ error }))

    if (error) {
      alert(error.message)
      return
    }

    const transaction = (() => {
      const t = {
        id: order_id,
        pay_type: 'Card',
        method: '1',
        access_id,
        tds2_ret_url: `${process.env.REACT_APP_API_URL}/api/payments/secure`
      }
      if (card_id) return { ...t, customer_id, card_id }
      return { ...t, card_no, expire, security_code }
    })()

    try {
      const response = await fincode.payments(transaction)
        .catch(error => ({ ...error, order_id }))

      const acsResult = await fincode.acsCheck("external", response.acs_url, access_id)
      fincode.tdsTransCheck(acsResult.tds2_trans_result)

      // 3DS Pass
      if (acsResult.tds2_trans_result === "Y") {
        await handlePaymentResult('success', order_id)
        return
      }

      // Required challenge 
      const tdsResult = await fincode.tdsCheck("external", acsResult.challenge_url, access_id, () => setLoading(false), () => setLoading(true))
      fincode.tdsTransCheck(tdsResult.tds2_trans_result)

      await handlePaymentResult('success', order_id)
    } catch (e) {
      console.log(e)
      const message = (e.errors) ? e.errors.map(e => e.error_message).join("\n") : e.message

      await handlePaymentResult('failure', order_id, message, transaction.card_id)
    }
  }

  useEffect(() => {
    if (confirmOpened)
      execute()
  }, [confirmOpened])

  Modal.setAppElement('#root');

  return (
    <>
      <Modal
        isOpen={confirmOpened}
        className="settlement-confirm-modal"
        overlayClassName="settlement-confirm-modal-overlay"
        contentLabel="Common Modal"
      >
        <div id="external">
          {loading && <div>{t('common.loading')}</div>}
        </div>
      </Modal>
    </>
  );
}

export default Settlement;
