import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import './Gacha.css'

import PointChange from './Modal/PointChange'
import PointNotEnough from './Modal/PointNotEnough'
import { useTranslation } from "react-i18next";

const GachaWonChange = ({moveAdress, move, cardList, selectedCards, setSelectedCards, id, userInfo, setUserInfo, shopPath, gachaWon, shopInfo, setShopInfo}) => {
    const { t } = useTranslation();

    let packageId = null;
    if (cardList.length != 0 && gachaWon == true) {
        packageId = cardList[0].package_id;
    }


    const [pointNotEnoughModalIsOpen, setPointNotEnoughModalIsOpen] = useState(false);

    const [changeModalIsOpen, setChangeModalIsOpen] = useState(false);
    const [changePoint, setChangePoint] = useState(0);
    
    useEffect(() => {
        let point = 0;
        // selectedCards.forEach(card => {
        //     let hitCard = cardList.find(cardList => cardList.hit_package_card_id === card);
        //     if (hitCard) {
        //         point += hitCard.point;
        //     }
        // });
        
        // 選択されていないカードのポイントを計算
        cardList.forEach(card => {
            if (!selectedCards.includes(card.hit_package_card_id)) {
                point += card.point;
            }
        });
        setChangePoint(point);
    }, [selectedCards,cardList]);

    const changeAllPoint = (e) => {
        // setSelectedCards(cardList.map(card => card.hit_package_card_id));
        setSelectedCards([]);
        setChangeModalIsOpen(true);
    }

    const shipOrder = (e) => {
        if(selectedCards.length != 0 && addressInfo.last_name == '') {
            alert(t('gacha_won_change.error'));
            return false;
        } else {
            setChangeModalIsOpen(true);
        }
    }

    const [isChanged, setIsChanged] = useState(false);

    const shipHandler = (e) => {
        if(selectedCards.length != 0 && addressInfo.last_name == '') {
            alert(t('gacha_won_change.error'));
            return false;
        } else if (Number(userInfo.point) + Number(changePoint) - Number(shopInfo.shipFee) < 0 && selectedCards.length != 0) {
            
            setPointNotEnoughModalIsOpen(true);
            setChangeModalIsOpen(false);

            return false;
        } else {
            if (isChanged == true) {
                return true;
            }

            setPointChangeButton(t('common.loading'));
            let token = document.cookie.split('; ').find(row => row.startsWith('token'));
            if (token) {
                token = token.split('=')[1]
            }
            fetch(process.env.REACT_APP_API_URL + '/api/package/ship', {
                method: 'POST',
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${decodeURIComponent(token)}`,
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    'hit_id': id,
                    'card_ids': selectedCards,
                    'ship_fee': shopInfo.shipFee,
                })
            })
            .then(response => {
                if (response.status == 200) {
                    return response.json()
                } else if (response.status == 400) {
                    return response.json()
                } else {
                    // throw new Error('エラーが発生しました')
                }
            })
            .then(data => {
                if (data.message) {
                    setShopInfo({...shopInfo, shipFee: data.ship_fee});
                    alert(data.message);
                } else {
                    setUserInfo({...userInfo, point: data.point});
                    setIsChanged(true);
                }
                setPointChangeButton(t('gacha_won_change.fixed'));
            })
            .catch(error => {
                setPointChangeButton(t('gacha_won_change.fixed'));
                alert(t('common.wait'));
                setIsChanged(false);
            });
        }
    }

    const holdOrder = (e) => {
        move('gacha', packageId)
    }

    const [addressInfo, setAddressInfo] = useState({last_name: '', first_name: '', address: {postal_code: '', prefecture: '', address: ''}});
    
    useEffect(() => {
        setAddressText(t('common.loading'));
        let token = document.cookie.split('; ').find(row => row.startsWith('token'));
        if (token) {
            token = token.split('=')[1]
        }
        fetch(process.env.REACT_APP_API_URL + '/api/address/default', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${decodeURIComponent(token)}`,
                'Accept': 'application/json',
            },
            credentials: 'include',
        })
        .then(response => {
            if (response.status == 200) {
                return response.json()
            } else {
                // throw new Error('エラーが発生しました')
            }
        })
        .then(data => {
            if (data.user_address)
                setAddressInfo(data.user_address)
            else
                setAddressText(t('gacha_won_change.error'));
                
        })
        .catch(error => {
            setAddressText(t('gacha_won_change.error'));
            console.log(error)
        });
    }, [])

    const [pointChangeButton, setPointChangeButton] = useState(t('gacha_won_change.fixed'));
    const [addressText, setAddressText] = useState(t('common.loading'));

    return (
        <>
            <div className="gacha-won-change-wrapper">
                <div className="gacha-won-change">
                    <div className="gacha-won-change-point">
                        <div className="gacha-won-change-title">{t('gacha_won_change.title')}</div>
                        <div className="gacha-won-change-point-text">{changePoint}pt</div>
                        <div className="gacha-won-change-number-text">{cardList.length-selectedCards.length}/{cardList.length}</div>
                    </div>
                    <div className="gacha-won-change-ship">
                        <div className="gacha-won-change-title">{t('gacha_won_change.address')}</div>
                        { addressInfo.last_name ? (
                            <>
                                <div className="mypage-ship-info" onClick={moveAdress}>
                                  <div className="mypage-ship-info-name">{addressInfo.last_name} {addressInfo.first_name}</div>
                                  {addressInfo.address.postal_code != '0000000' &&
                                    <div className="mypage-ship-info-postcode">〒{addressInfo.address.postal_code}</div>
                                  }
                                  <div className="mypage-ship-info-address">{addressInfo.address.address}</div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="gacha-won-change-ship-info" onClick={moveAdress}>
                                    <div className="gacha-won-change-ship-info-name">{addressText}</div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="gacha-won-change-button">
                        {gachaWon ?
                            <>
                                <div className="gacha-won-change-button-change-wrapper">
                                    <button className="gacha-won-change-button-change gacha-won-change-button-change-half" onClick={changeAllPoint}>{t('gacha_won_change.change')}</button>
                                    <button className="gacha-won-change-button-change gacha-won-change-button-change-half" onClick={holdOrder}>{t('gacha_won_change.pending')}</button>
                                </div>
                            </>
                            :
                            <>
                                <button className="gacha-won-change-button-change" onClick={changeAllPoint}>{t('gacha_won_change.change')}</button>
                            </>
                        }
                        {selectedCards.length == 0 ? (
                            <>
                                <button className="gacha-won-change-button-ship">{t('gacha_won_change.ship')}</button>
                            </>
                        ) : (
                            <>
                                <button className="gacha-won-change-button-ship gacha-won-change-button-ship-active" onClick={shipOrder}>{selectedCards.length}{t('gacha_won_change.card')}{t('gacha_won_change.ship')}</button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {changeModalIsOpen && <PointChange 
                modalIsOpen={changeModalIsOpen}
                changeModalIsOpen={setChangeModalIsOpen}
                move={move}
                selectedCards={selectedCards}
                setSelectedCards={setSelectedCards}
                cardList={cardList}
                changePoint={changePoint}
                shipHandler={shipHandler}
                shopPath={shopPath}
                packageId={packageId}
                pointChangeButton={pointChangeButton}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                shopInfo={shopInfo}
            />}
            <PointNotEnough modalIsOpen={pointNotEnoughModalIsOpen} changeModalIsOpen={setPointNotEnoughModalIsOpen} move={move} shipFlag={true}/>
        </>
    )
}

export default GachaWonChange