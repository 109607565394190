import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

import './Index.css'
import { useTranslation } from "react-i18next";

const Package = ({oripa, move, userInfo}) => {
    const { t } = useTranslation();

    const stockPercent = oripa.stock / oripa.total * 100 > 99 ? 99 : oripa.stock / oripa.total * 100;
    const [justifyContent, setJustifyContent] = useState('center');
    const cards = oripa.package_cards.filter(card => card.rank <= 2).slice(0, 10);

    const [packageClass, setPackageClass] = useState('package');
    const [isSoldOut, setIsSoldOut] = useState(false);
    const [drawableCount, setDrawableCount] = useState(0);

    useEffect(() => {
        let packageInfo = oripa;
        // 残りガチャが何回引けるかどうか計算
        let drawable = 0;
        if (packageInfo.depends_on_hold_points) {   
            if (packageInfo.price > 0 && userInfo) {
                drawable = Math.floor(userInfo.point / packageInfo.price);
            }
        } else {
            drawable = packageInfo.max_number_per_one_draw;
        }
        if (packageInfo.stock > 0 && packageInfo.stock < drawable) {
            drawable = packageInfo.stock;
        }
        if (drawable > packageInfo.max_number_per_one_draw) {
            drawable = packageInfo.max_number_per_one_draw;
        }
        if (drawable > packageInfo.left_limited_number_per_user) {
            drawable = packageInfo.left_limited_number_per_user;
        }
        if (drawable > packageInfo.left_limited_number_per_user_per_day) {
            drawable = packageInfo.left_limited_number_per_user_per_day;
        }
        if (drawable < 1) {
            drawable = 1;
        }
        if (packageInfo.stock == -1) {
            drawable = packageInfo.max_number_per_one_draw;
        }
        if (packageInfo.stock == -2) {
            drawable = 1;
        }

        setDrawableCount(drawable);
    }, [oripa]);

    useEffect(() => {
        if (oripa.status === 2 || oripa.status === 6) {
            setPackageClass('package')
            setIsSoldOut(false);
        } else if (oripa.status === 3) {
            setPackageClass('package package-sold-out')
            setIsSoldOut(t('package.soldout'));
        } else if (oripa.status === 4) {
            setPackageClass('package package-end')
            setIsSoldOut(t('package.end'));
        }
    }, [oripa.status]);

    useEffect(() => {
        const cardWidth = 100; // 画像の幅を設定
        const windowWidth = window.innerWidth; // 画面の幅を取得

        // 画像の合計幅が画面幅を超えるかどうかを判断
        if (cardWidth * cards.length > windowWidth) {
            setJustifyContent('flex-start');
        } else {
            setJustifyContent('center');
        }
    }, [cards]);

    const moveGacha = (e) => {
        e.preventDefault();
        if (oripa.status === 2 || oripa.status === 6) {
            move('gacha', oripa.id);
        }
    }

    const moveGachaOne = (e) => {
        e.preventDefault();
        if (oripa.status === 2 || oripa.status === 6) {
            move('gacha', oripa.id, 'single');
        }
    }

    const moveGachaMulti = (e) => {
        e.preventDefault();
        if (oripa.status === 2 || oripa.status === 6) {
            move('gacha', oripa.id, 'multi');
        }
    }

    return (
        <>
            <div className={packageClass}>
                {isSoldOut && 
                    <div className="after-content" style={{
                        position: 'absolute',
                        top: '35%',
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '3rem',
                        fontWeight: 600,
                        color: '#000',
                        zIndex: 999,
                    }}>
                        {isSoldOut}
                    </div>
                }
                <div className="package-image" onClick={moveGacha}>
                    <img src={oripa.img_path} alt={oripa.name} />
                    </div>
                    <div className="package-cards" style={{ justifyContent: justifyContent }}>
                        <div className="arrow arrow-left"><img src="/img/left.svg" alt="<" /></div>
                        <div className="arrow arrow-right"><img src="/img/right.svg" alt=">" /></div>
                        {oripa.package_cards.filter(card => card.rank <= 2).slice(0, 10).map((card, index) => (
                            <img key={index} src={process.env.REACT_APP_ASSET_URL + card.image_url} alt={oripa.name} />
                        ))}
                    </div>
                    <div className="require-pt">
                        <div className="require-pt-icon">
                            <img src="/img/balance.svg" alt="pt" />
                        </div>
                        <div className="require-pt-value">{oripa.point.toLocaleString()} pt</div>
                    </div>

            </div>
            <div className="stock">
                {!oripa.hidden_from || oripa.stock == 0 || oripa.stock > oripa.hidden_from ? (
                    <>
                        <div className="stock-seek-bar">
                            <div 
                                className={
                                    oripa.stock / oripa.total * 100 < 25 ? 'stock-seek-bar-inner stock-seek-bar-inner-25-percent' :
                                    oripa.stock / oripa.total * 100 < 50 ? 'stock-seek-bar-inner stock-seek-bar-inner-50-percent' :
                                    oripa.stock / oripa.total * 100 < 75 ? 'stock-seek-bar-inner stock-seek-bar-inner-75-percent' :
                                    'stock-seek-bar-inner stock-seek-bar-inner-75-percent'
                                }
                                style={{ width: (oripa.stock / oripa.total * 100 > 99 ? 99 : oripa.stock / oripa.total * 100)+'%' }}>
                            </div>
                        </div>
                        <div className="stock-value">
                            {t('package.stock')} <span className="stock-now-text">{oripa.stock.toLocaleString()}</span>/<span className="stock-total-text">{oripa.total.toLocaleString()}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="stock-seek-bar">
                            <div className="stock-seek-bar-inner stock-seek-bar-inner-rainbow"></div>
                        </div>
                        <div className="stock-value">
                            {t('package.stock')} <span className="stock-now-text">???</span>/<span className="stock-total-text">{oripa.total.toLocaleString()}</span>
                        </div>
                    </>
                )}
            </div>
            <div className="gacha-button-top">
                {oripa.status !== 2 || oripa.over_limited_number_per_user || oripa.over_limited_number_per_user_per_day ?
                    <>
                        {/* <button className="gacha-button-1" onClick={moveGacha}> */}
                        <button className="gacha-button-1 gacha-button-disabled">
                            <img src={document.getElementById("favicon").href}/>
                            {t('gacha.gacha1')}1{t('gacha.gacha2')}
                        </button>
                        {/* <button className="gacha-button-10" onClick={moveGacha}> */}
                        <button className="gacha-button-10 gacha-button-disabled">
                            <img src={document.getElementById("favicon").href}/>
                            {/* {t('gacha.gacha1')}{drawableCount}{t('gacha.gacha2')} */}
                            {t('gacha.gacha1')}{drawableCount}{t('gacha.gacha2')}
                        </button>
                    </>
                    :
                    <>
                        {/* <button className="gacha-button-1" onClick={moveGacha}> */}
                        <button className="gacha-button-1" onClick={moveGachaOne}>
                            <img src={document.getElementById("favicon").href}/>
                            {t('gacha.gacha1')}1{t('gacha.gacha2')}
                        </button>
                        {/* <button className="gacha-button-10" onClick={moveGacha}> */}
                        <button className="gacha-button-10" onClick={moveGachaMulti}>
                            <img src={document.getElementById("favicon").href}/>
                            {/* {t('gacha.gacha1')}{drawableCount}{t('gacha.gacha2')} */}
                            {t('gacha.gacha1')}{drawableCount}{t('gacha.gacha2')}
                        </button>
                    </>
                }
            </div>
        </>
    )
}

export default Package