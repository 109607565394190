import React from 'react'
import { useEffect } from 'react'

import './Index.css'

import Package from './Package'
import { useTranslation } from "react-i18next";

const Content = ({move, shopInfo, userInfo}) => {
    const { t } = useTranslation();
    const [packageType, setPackageType] = React.useState(1);
    const [packageMasterList, setPackageMasterList] = React.useState([]);

    const [packageList, setPackageList] = React.useState([]);

    const [loadingText, setLoadingText] = React.useState(t('common.loading'));

    const changePackageType = (e) => {
        setPackageType(e.target.value);
        document.cookie = `package_type=${e.target.value}; max-age=2592000; path=/`;
    }

    const PacakgeTypeMasterList = () => {
        return packageMasterList.map((packageMaster) => {
            if (packageList.map((packageInfo) => {return packageInfo.type.toString()}).indexOf(packageMaster.package_type.toString()) === -1) {
                return null;
            } else {
                return (
                    <li key={packageMaster.id}
                    {...packageType.toString() === packageMaster.package_type ? {className: 'package-type package-type-selected'} : {className: 'package-type'}}
                    value={packageMaster.package_type} onClick={changePackageType}>
                        {t("package_master." + packageMaster.package_type_name)}
                    </li>
                )
            }
        });
    }

    const PacakgeTypeMasterListButton = () => {
        return packageMasterList.map((packageMaster) => {
            if (packageList.map((packageInfo) => {return packageInfo.type.toString()}).indexOf(packageMaster.package_type.toString()) === -1) {
                return null;
            } else {
                return (
                    <button key={packageMaster.id}
                    {...packageType.toString() === packageMaster.package_type ? {className: 'package-type package-type-selected'} : {className: 'package-type'}}
                    value={packageMaster.package_type} onClick={changePackageType}>
                        {t("package_master." + packageMaster.package_type_name)}
                    </button>
                )
            }
        });
    }

    const CreatePackageList = () => {
        if (packageList.map((packageInfo) => {return packageInfo.type.toString()}).indexOf(packageType.toString()) === -1) {
            return (
                <div style={{textAlign: 'center'}}>
                    {loadingText}
                </div>
            )
        }

        return packageList.map((packageInfo) => {
            if (packageInfo.type.toString() === packageType.toString() ) {
                return (
                    <Package key={packageInfo.id}
                        oripa={{
                            img_path: process.env.REACT_APP_ASSET_URL + packageInfo.image_url,
                            point: packageInfo.price,
                            stock: packageInfo.stock,
                            total: packageInfo.number,
                            id: packageInfo.id,
                            status: packageInfo.status,
                            hidden_from: packageInfo.hidden_from,
                            name: packageInfo.name,
                            package_cards: packageInfo.package_cards,
                            left_limited_number_per_user: packageInfo.left_limited_number_per_user,
                            left_limited_number_per_user_per_day: packageInfo.left_limited_number_per_user_per_day,
                            max_number_per_one_draw: packageInfo.max_number_per_one_draw,
                            over_limited_number_per_user: packageInfo.over_limited_number_per_user,
                            over_limited_number_per_user_per_day: packageInfo.over_limited_number_per_user_per_day,
                        }}
                        move={move}
                        userInfo={userInfo}
                    />
                )
            }
        });
    }

    const [isPackageLoaded, setIsPackageLoaded] = React.useState(false);

    useEffect(() => {
        if (shopInfo.id && !isPackageLoaded) {
            fetch(`${process.env.REACT_APP_API_URL}/api/package-master?shop_id=${shopInfo.id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
                credentials: 'include',
            })
            .then(response => response.json())
            .then(data => {
                if(!data.errors) {
                    setPackageMasterList(data.package_type_masters);
                } else {
                    alert(Object.values(data.errors)[0]);
                }
            })
            .catch(error => {
                console.error(error);
            });


            let api = `${process.env.REACT_APP_API_URL}/api/packages?shop_id=${shopInfo.id}`
            let token = document.cookie.split('; ').find(row => row.startsWith('token'));
            if (token) {
                token = token.split('=')[1]
                api = `${process.env.REACT_APP_API_URL}/api/packages/user?shop_id=${shopInfo.id}`
            }

            // packagecard list            
            fetch(api, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${decodeURIComponent(token)}`,
                    'Accept': 'application/json',
                },
                credentials: 'include',
            })
            .then(response => response.json())
            .then(data => {
                if(!data.errors) {
                    setPackageList(data.packages);
                } else {
                    alert(Object.values(data.errors)[0]);
                }
                setLoadingText(t('content.error'));
                setIsPackageLoaded(true);
            })
        }
    }, [shopInfo.id]);

    useEffect(() => {
        let list = packageList.map((packageInfo) => {return packageInfo.type.toString()});
        if (list.length > 0) {
            // cookieに保存されているパッケージタイプがあればそれを優先する
            let cookie = document.cookie.split('; ').find(row => row.startsWith('package_type'));
            if (cookie) {
                cookie = cookie.split('=')[1]
                if (list.indexOf(cookie) !== -1) {
                    setPackageType(cookie);
                    return;
                }
            }
            // なければ一番小さいパッケージタイプを優先する
            setPackageType(Math.min.apply(null, list));
        }
    }, [packageList]);

    useEffect(() => {
        let list = packageList.slice(0, 5);
        let nameList = [];
        for (let i = 0; i < list.length; i++) {
            nameList.push(list[i].name);
        }
        let name = nameList.join('」、「');
        let shopName = shopInfo.name;

        document.title = shopName

        let metaDiscre = document.head.children;
        let metaLength = metaDiscre.length;
        for(var i = 0;i < metaLength;i++){
            let proper = metaDiscre[i].getAttribute('name');
            if(proper === 'description'){
                let content = metaDiscre[i].getAttribute('content');
                if (nameList.length > 0) {
                    metaDiscre[i].setAttribute('content', t('content.description1') + shopName +  t('content.description2') + t('content.description3') + name + t('content.description4'));
                } else {
                    metaDiscre[i].setAttribute('content', t('content.description1') + shopName +  t('content.description2'));
                }
            }
        }

        // twitter card
        let metaTwitter = document.head.children;
        let metaTwitterLength = metaTwitter.length;
        for(var i = 0;i < metaTwitterLength;i++){
            let proper = metaTwitter[i].getAttribute('name');
            if(proper === 'twitter:description'){
                let content = metaTwitter[i].getAttribute('content');
                if (nameList.length > 0) {
                    metaTwitter[i].setAttribute('content', t('content.description1') + shopName +  t('content.description2') + t('content.description3') + name + t('content.description4'));
                } else {
                    metaTwitter[i].setAttribute('content', t('content.description1') + shopName +  t('content.description2'));
                }
            }
            if(proper === 'twitter:title'){
                let content = metaTwitter[i].getAttribute('content');
                metaTwitter[i].setAttribute('content', shopName);
            }
            if(proper === 'twitter:image'){
                if (packageList.length === 0) {
                    continue;
                }
                let content = metaTwitter[i].getAttribute('content');
                metaTwitter[i].setAttribute('content', process.env.REACT_APP_ASSET_URL + packageList[0].image_url);
            }
        }

        // ogp
        let metaOgp = document.head.children;
        let metaOgpLength = metaOgp.length;
        for(var i = 0;i < metaOgpLength;i++){
            let proper = metaOgp[i].getAttribute('property');
            if(proper === 'og:description'){
                let content = metaOgp[i].getAttribute('content');
                if (nameList.length > 0) {
                    metaOgp[i].setAttribute('content', t('content.description1') + shopName +  t('content.description2') + t('content.description3') + name + t('content.description4'));
                } else {
                    metaOgp[i].setAttribute('content', t('content.description1') + shopName +  t('content.description2'));
                }
            }
            if(proper === 'og:title'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', shopName);
            }
            if(proper === 'og:image'){
                if (packageList.length === 0) {
                    continue;
                }
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', process.env.REACT_APP_ASSET_URL + packageList[0].image_url);
            }
            if(proper === 'og:url'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', window.location.href);
            }
            if(proper === 'og:site_name'){
                let content = metaOgp[i].getAttribute('content');
                metaOgp[i].setAttribute('content', shopName);
            }
        }

    }, [packageList]);

    return (
        <>
            <div className="contents">
                <div className="package-nav">
                    {/* <ul className="package-types">
                        <PacakgeTypeMasterList />
                    </ul> */}
                    <PacakgeTypeMasterListButton />
                </div>
            </div>
            <CreatePackageList />
        </>
    )
}

export default Content
