import React from 'react'
import { useState } from 'react'
import Slick from 'react-slick'

import './Gacha.css'
import { useTranslation } from "react-i18next";

const GachaRank3Info = ({cards}) => {
    const { t } = useTranslation();

    const makeImage = (cards) => {
        return cards.map((card) => {
            return (
                <div className="gacha-info-card-image-rank3" key={card.id}>
                    <img src={process.env.REACT_APP_ASSET_URL + card.image_url} alt="card" />
                    { card.is_psa_enabled == 1 && <div className="gacha-info-card-psa">PSA{card.psa}</div>}
                    {card.rank == 9 ?
                    <div className="gacha-info-card-number">{t('gacha_rank_info.info')}{card.number}</div>
                    :
                    <div className="gacha-info-card-number">×{card.number}</div>
                    }
                </div>
            )
        })
    }

    return (
        <>
            {makeImage(cards)}
        </>
    )
}

export default GachaRank3Info