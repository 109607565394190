// カードを新規追加するモーダルのコンポーネント

import React, { useState } from 'react';

import './PointChange.css';

// React Modalで実装
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";

const PointChange = ({modalIsOpen, changeModalIsOpen, move, selectedCards, cardList, changePoint, shipHandler, shopPath, packageId, pointChangeButton, isChanged, setIsChanged, shopInfo}) => {
    const { t } = useTranslation();

    const changePointSubmit = (e) => {
        shipHandler(e)
    }

    const closeModal = () => {
        changeModalIsOpen(false);
    }

    const moveIndex = () => {
        // move('index');
        // window.location.reload();
        if (packageId == null) {
            move('index');
        } else {
            move('gacha', packageId);
        }
    }

    Modal.setAppElement('#root');
    
    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                className="point-change-modal"
                overlayClassName="point-change-modal-overlay"
                contentLabel="Common Modal"
            >
                <div className="point-change-modal-content">
                    {isChanged ? 
                        <>
                            <div className="point-change-modal-title">{t('point_change.title1')}</div>
                            <div className="point-change-modal-text">
                            {t('point_change.text1')}<br />
                            {t('point_change.text2')}<br />
                                <span className="point-change-modal-text-note">{t('point_change.note')}</span>
                            </div>
                            <div className="point-change-modal-button">
                                <button className="point-change-modal-button-charge" onClick={moveIndex}>{t('point_change.back')}</button>
                            </div>
                        </>
                    :
                        <>
                            <div className="point-change-modal-title">{t('point_change.title2')}</div>
                            <div className="point-change-modal-text">{t('point_change.text3')}</div>
                            <div className="point-change-modal-change-info">
                                <div className="point-change-modal-change-info-title">{t('point_change.change')}</div>
                                <div className="point-change-modal-change-info-content">
                                    <img className="point-change-modal-change-info-image" src="/img/balance.svg" />
                                    {changePoint}pt
                                </div>
                            </div>
                            {shopInfo.shipFee != 0 && selectedCards.length != 0 &&
                                <>
                                    <div className="point-change-modal-change-info">
                                        <div className="point-change-modal-change-info-title">{t('point_change.ship')}</div>
                                        <div className="point-change-modal-change-info-content">
                                            <img className="point-change-modal-change-info-image" src="/img/balance.svg" />
                                            -{shopInfo.shipFee}pt
                                        </div>
                                    </div>
                                    <div className='clearfix'></div>
                                    <div className='line'></div>
                                    {changePoint - shopInfo.shipFee >= 0 ?
                                        <>
                                            <div className="point-change-modal-change-info">
                                                <div className="point-change-modal-change-info-title">
                                                    {t('point_change.change')}
                                                </div>
                                                <div className="point-change-modal-change-info-content">
                                                    <img className="point-change-modal-change-info-image" src="/img/balance.svg" />
                                                    {changePoint - shopInfo.shipFee}pt
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="point-change-modal-change-info color-red">
                                                <div className="point-change-modal-change-info-title">
                                                    {t('point_change.used')}
                                                </div>
                                                <div className="point-change-modal-change-info-content">
                                                    <img className="point-change-modal-change-info-image" src="/img/balance.svg" />
                                                    {shopInfo.shipFee - changePoint}pt
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className='clearfix'></div>
                                </>
                            }
                            <div className="point-change-modal-button">
                                <button className="point-change-modal-button-charge" onClick={changePointSubmit}>{pointChangeButton}</button>
                                <button className="point-change-modal-button-close" onClick={closeModal}>{t('point_change.close')}</button>
                            </div>
                        </>
                    } 
                </div>
            </Modal>
        </>
    );
}

export default PointChange;